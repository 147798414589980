import React, { useContext, useState } from "react"
import { AuthContext } from "../../../utils/providers/auth"
import { BookingContext } from "../../../utils/providers/booking"
import { ModalContext } from "../../../utils/providers/modal"
import { AlertsContext } from "../../../utils/providers/alerts"
import { auth, db } from "../../../utils/firebase"
import firebase from "firebase"

/**
 * UI components
 */
import Input from "../../../components/ui/inputs/input"
import Select from "../../../components/ui/select/select"
import Button from "../../../components/ui/button/button"
import Datepicker from "../../../components/ui/datepicker/datepicker"
import Textarea from "../../../components/ui/inputs/textarea"
import AddPayment from "../../../components/payments/add/add"
import ViewingPayments from "../../../components/payments/view/view"
import { EyeIcon, PlusIcon } from "../../../utils/svgs"

/**
 * Functional component to return the booking summary panel
 */
function Summary(props) {
	const [addPayment, setAddPayment] = useState(false)
	const [viewingPayments, setViewingPayments] = useState(false)
	const [cancelling, setCancelling] = useState(false)
	const [removing, setRemoving] = useState(false)

	/**
	 * Deconstruct some data from the props
	 */
	const { client, clientID } = props

	/**
	 * Pull the show modal function from the modal context
	 */
	const { showModal } = useContext(ModalContext)

	/**
	 * Pull the alerts function from the alerts context
	 */
	const { pushAlert } = useContext(AlertsContext)

	/**
	 * Get the current users details from the auth context
	 */
	const { user } = useContext(AuthContext)

	/**
	 * Deconstruct data from the booking context
	 */
	const {
		bookingID,
		removed,
		saveBookingDetails,
		agents,
		agent,
		setAgent,
		reference,
		booked,
		confirmed,
		setConfirmed,
		golfers,
		setGolfers,
		nonGolfers,
		setNonGolfers,
		totalCost,
		paidByClient,
		outstanding,
		balanceDue,
		commentsForClient,
		setCommentsForClient,
		commentsFromClient,
		hideFromOverdue,
		setHideFromOverdue,
	} = useContext(BookingContext)

	/**
	 * Show a modal first to check before cancelling the booking
	 */
	const checkForCancellation = async () => {
		/**
		 * Display the modal to check before a booking cancellation
		 */
		showModal({
			type: "ALERT",
			title: "Are you sure?",
			body: "Cancelling this booking will remove it from all statistics and send cancellation emails to all suppliers.",
			cancel: {
				label: "Cancel",
				action: () => {
					return null
				},
			},
			next: {
				label: "Yes, i'm sure",
				action: () => cancelBooking(),
			},
		})
	}

	/**
	 * Cancel the booking by marking the "removed" field to true
	 */
	const cancelBooking = async () => {
		/**
		 * Show a spinner
		 */
		setCancelling(true)
		/**
		 * Pull the original booking data and make a copy into the cancellation document
		 */
		const originalBooking = await db
			.doc(`bookings/${bookingID}`)
			.get()
			.then((bookingDoc) => {
				return { ...bookingDoc.data() }
			})
		/**
		 * Add a new record into the "cancellations" collection
		 */
		const cancellationID = await db.collection("cancellations").add({
			bookingID: bookingID,
			cancelled_at: firebase.firestore.FieldValue.serverTimestamp(),
			resort_confirmed: false,
			booking_data: originalBooking,
		})
		/**
		 * Change the "removed" field
		 */
		await db.doc(`bookings/${bookingID}`).set(
			{
				removed: true,
				cancellationID: cancellationID,
			},
			{ merge: true }
		)
		/**
		 * Add a log to the client record showing their booking was deleted
		 */
		await db.collection(`clients/${clientID}/logs`).add({
			created: firebase.firestore.FieldValue.serverTimestamp(),
			message: "Booking was cancelled",
			type: "BOOKING_CANCELLED",
			badge: "INFO",
			user: auth.currentUser.uid,
			metadata: {
				bookingID: bookingID,
			},
		})
		/**
		 * Hide the spinner
		 */
		setCancelling(false)
	}

	/**
	 * Show a modal first to check before cancelling the booking
	 */
	const checkForRemoval = async () => {
		/**
		 * Display the modal to check before a booking cancellation
		 */
		showModal({
			type: "ALERT",
			title: "Are you sure?",
			body: "Removing this booking will wipe all data for it from the system.",
			cancel: {
				label: "Cancel",
				action: () => {
					return null
				},
			},
			next: {
				label: "Yes, i'm sure",
				action: () => removeBooking(),
			},
		})
	}

	/**
	 * Cancel the booking by marking the "removed" field to true
	 */
	const removeBooking = async () => {
		/**
		 * Show a spinner
		 */
		setRemoving(true)
		/**
		 * Change the "removed" field
		 */
		await db.doc(`bookings/${bookingID}`).delete()
		/**
		 * Add a log to the client record showing their booking was deleted
		 */
		await db.collection(`clients/${clientID}/logs`).add({
			created: firebase.firestore.FieldValue.serverTimestamp(),
			message: "Booking was removed",
			type: "BOOKING_REMOVED",
			badge: "INFO",
			user: auth.currentUser.uid,
		})
		/**
		 * Then redirect the user
		 */
		window.location.href = "/bookings"
		/**
		 * Hide the spinner
		 */
		setRemoving(false)
	}

	/**
	 * Update the booked timestamp into firestore
	 */
	const updateBookedDate = async (date) => {
		/**
		 * Push the new booked date into the database
		 */
		await db.doc(`bookings/${bookingID}`).set(
			{
				booked: firebase.firestore.Timestamp.fromMillis(date) || null,
			},
			{ merge: true }
		)
		/**
		 * Show an alert to show it's been saved
		 */
		pushAlert({
			type: "SUCCESS",
			title: "Booked Date Updated",
		})
	}

	/**
	 * Update the balance due timestamp into firestore
	 */
	const updateBalanceDue = async (date) => {
		/**
		 * Push the new balance due date into the database
		 */
		await db.doc(`bookings/${bookingID}`).set(
			{
				balance_due: firebase.firestore.Timestamp.fromMillis(date) || null,
			},
			{ merge: true }
		)
		/**
		 * Show an alert to show it's been saved
		 */
		pushAlert({
			type: "SUCCESS",
			title: "Balance Due Updated",
		})
	}

	return (
		<>
			<table className="booking-table">
				<tbody>
					<tr>
						<td>Client details</td>
						<td>
							<Input
								type="text"
								value={`${client.first_name} ${client.last_name}`}
								readOnly={true}
							/>
						</td>
					</tr>
					<tr>
						<td>Assigned agent</td>
						<td>
							<Select
								placeholder="Choose agent"
								value={agent.value}
								selected={agent.option}
								activeOnHover={true}
								onSelect={(option) => setAgent(option)}
								options={agents}
								readOnly={!user?.is_super_admin && auth.currentUser.uid !== "7GFYd2EvA9OiZxcufgbz5CRcssu1"}
							/>
						</td>
					</tr>
					<tr>
						<td>Booking ref</td>
						<td>
							<Input
								type="text"
								value={reference}
								readOnly={true}
							/>
						</td>
					</tr>
					<tr>
						<td>Booked date</td>
						<td>
							<Datepicker
								placeholder=""
								value={booked}
								onSelect={(date) => updateBookedDate(date)}
								activeOnHover={true}
							/>
						</td>
					</tr>
					<tr>
						<td>Booking confirmed?</td>
						<td>
							<Select
								placeholder="Choose status:"
								value={confirmed ? "Yes" : "No"}
								activeOnHover={true}
								onSelect={(option) => setConfirmed(option.option === "true" ? true : false)}
								options={{
									true: "Yes",
									false: "No",
								}}
							/>
						</td>
					</tr>
					<tr>
						<td>Group size</td>
						<td>
							<div className="table-data-split cols-2">
								<div className="table-data-column">
									<p>Golfers</p>
									<Input
										type="number"
										value={golfers}
										onChange={setGolfers}
										activeOnHover={true}
										onBlur={() => saveBookingDetails()}
									/>
								</div>

								<div className="table-data-column">
									<p>Non-golfers</p>
									<Input
										type="number"
										value={nonGolfers}
										onChange={setNonGolfers}
										activeOnHover={true}
										onBlur={() => saveBookingDetails()}
									/>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>Total holiday cost</td>
						<td>
							<Input
								type="text"
								symbol="£"
								placeholder="Total cost:"
								value={totalCost}
								readOnly={true}
							/>
						</td>
					</tr>
					<tr>
						<td>Paid by client</td>
						<td>
							<Input
								type="text"
								symbol="£"
								placeholder="Total paid:"
								value={paidByClient}
								readOnly={true}
							/>
						</td>
						<td>
							<Button
								icon={<PlusIcon />}
								xsSmall={true}
								onClick={() => setAddPayment(true)}
							/>
						</td>
						<td className="vertical-center">
							<Button
								icon={<EyeIcon />}
								xsSmall={true}
								onClick={() => setViewingPayments(true)}
							/>
						</td>
					</tr>
					<tr>
						<td>Balance outstanding</td>
						<td>
							<Input
								type="text"
								symbol="£"
								placeholder="Total outstanding:"
								value={outstanding}
								readOnly={true}
							/>
						</td>
					</tr>
					<tr>
						<td>Balance due</td>
						<td>
							<Datepicker
								placeholder="Choose date:"
								value={balanceDue}
								onSelect={(date) => updateBalanceDue(date)}
								activeOnHover={true}
							/>
						</td>
					</tr>
					{["CGOHCdUwFAOe13OSTY2D9qdO2LX2", "iUlTXjfx1OZEPBhPosh6rJt3CKe2", "mJQ6mUzWPMhVyCQGR4nNF6MDBoo2"].includes(auth.currentUser.uid) && (
						<tr>
							<td>Hide from overdue?</td>
							<td>
								<Select
									placeholder="Choose status:"
									value={hideFromOverdue ? "Yes" : "No"}
									activeOnHover={true}
									onSelect={(option) => setHideFromOverdue(option.option === "true" ? true : false)}
									options={{
										true: "Yes",
										false: "No",
									}}
								/>
							</td>
						</tr>
					)}
					<tr>
						<td>Comments for client</td>
						<td>
							<Textarea
								type="text"
								value={commentsForClient}
								onChange={setCommentsForClient}
								placeholder="Comments to show the client:"
								activeOnHover={true}
								onBlur={() => saveBookingDetails()}
							/>
						</td>
					</tr>
					<tr>
						<td>Comments from client</td>
						<td>
							<Textarea
								type="text"
								value={commentsFromClient}
								placeholder="No comments from client"
								readOnly={true}
							/>
						</td>
					</tr>
					<tr>
						<td>Booking actions</td>
						<td className="vertical-center">
							<Button
								badge={true}
								label={removed ? "Booking cancelled" : "Cancel booking"}
								loading={cancelling}
								loadingText="Cancelling..."
								onClick={() => checkForCancellation()}
								disabled={removed}
							/>
						</td>
					</tr>
				</tbody>
			</table>

			{addPayment && (
				<AddPayment
					clientID={clientID}
					client={client.first_name}
					bookingID={bookingID}
					close={() => setAddPayment(false)}
				/>
			)}

			{viewingPayments && (
				<ViewingPayments
					bookingID={bookingID}
					close={() => setViewingPayments(false)}
				/>
			)}
		</>
	)
}

export default Summary
