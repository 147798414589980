import React, { useState, useEffect, useContext } from "react"
import { AlertsContext } from "../../../utils/providers/alerts"
import { db, auth } from "../../../utils/firebase"
import firebase from "firebase"

/**
 * UI components
 */
import Input from "../../ui/inputs/input"
import Button from "../../ui/button/button"
import Window from "../../structure/window/window"
import WindowCard from "../../ui/window-card/window-card"
import Datepicker from "../../ui/datepicker/datepicker"
import { LoadingIcon } from "../../../utils/svgs"
import MiniVoucher from "./images/mini-voucher.png"
import Badge from "../../ui/badge/badge"

/**
 * Functional component to return the add payment form
 */
export default function AddPayment(props) {
	const [saving, setSaving] = useState(false)
	const [amount, setAmount] = useState("")
	const [reference, setReference] = useState("")
	const [payee, setPayee] = useState("")
	const [paidMillis, setPaidMillis] = useState("")
	const [fetchingVouchers, setFetchingVouchers] = useState(true)
	const [vouchers, setVouchers] = useState([])

	// Pull the alerts in for showing in the DOM
	const { pushAlert } = useContext(AlertsContext)

	// When the modal mounts
	useEffect(() => {
		// Make a call to see if there are any vouchers availble for this client
		const fetchVouchers = async () => {
			await db
				.collection("gift_vouchers")
				.where("purchased_for", "==", props.clientID)
				.get()
				.then((voucherDocs) => {
					let allVouchers = []
					voucherDocs.docs.forEach((doc) => {
						allVouchers.push({ id: doc.id, ...doc.data() })
					})
					setVouchers(allVouchers)
					setFetchingVouchers(false)
				})
		}
		fetchVouchers()
	}, [])

	/**
	 * Deconstruct some data from the props
	 */
	const { client, bookingID } = props

	/**
	 * Save the payment data into the "payments" subcollection on the booking
	 */
	const savePayment = async () => {
		/**
		 * Show the loading spinner
		 */
		setSaving(true)
		/**
		 * Get the amount in true Number format
		 */
		const amountVal = Number.parseFloat(amount)
		/**
		 * Add the payment data into the collection
		 */
		await db.collection(`bookings/${bookingID}/payments`).add({
			amount: amountVal,
			payee,
			reference,
			paid_date: firebase.firestore.Timestamp.fromMillis(paidMillis),
			created: firebase.firestore.FieldValue.serverTimestamp(),
			saved_by: auth.currentUser.uid,
		})
		/**
		 * Reset the state
		 */
		setSaving(false)
		props.close()
	}

	// When a voucher is chosen from the list
	const useVoucher = async (voucherID) => {
		// If it does not have a status of "PAID" then do not allow it to be used
		if (vouchers.find((voucher) => voucher.id === voucherID).status !== "PAID") {
			pushAlert({
				type: "ERROR",
				title: "Gift voucher is not available.",
			})
			return
		}

		// Find the voucher in the list
		const voucher = vouchers.find((voucher) => voucher.id === voucherID)

		// Add the payment data into the collection
		await db.collection(`bookings/${bookingID}/payments`).add({
			amount: voucher.value ? Number.parseFloat(voucher.value) : 0,
			payee: "Gift Voucher",
			reference: `Gift Voucher - ${voucher.id}`,
			paid_date: firebase.firestore.Timestamp.now(),
			created: firebase.firestore.FieldValue.serverTimestamp(),
			saved_by: auth.currentUser.uid,
		})

		// Then update the voucher status to redeemed
		await db.doc(`gift_vouchers/${voucherID}`).update({
			status: "REDEEMED",
			bookingID: bookingID,
		})

		// Then close the modal
		props.close()
	}

	return (
		<Window
			title={`Add payment for ${client}`}
			className="slim"
			close={() => props.close()}>
			<WindowCard>
				<div className="input-grid cols-2">
					<div className="span-2">
						<Input
							type="text"
							placeholder="Payee:"
							value={payee}
							onChange={setPayee}
						/>
					</div>

					<Input
						type="number"
						placeholder="Amount:"
						symbol="£"
						value={amount}
						onChange={setAmount}
					/>

					<Input
						type="text"
						placeholder="Reference:"
						value={reference}
						onChange={setReference}
					/>

					<div className="span-2">
						<Datepicker
							placeholder="Payment date:"
							onSelect={(date) => setPaidMillis(date)}
						/>
					</div>

					<div className="span-2">
						<Button
							label="Save payment"
							fullWidth={true}
							loading={saving}
							loadingText="Saving..."
							onClick={() => savePayment()}
							disabled={!amount || !payee || !reference || !paidMillis}
						/>
					</div>
				</div>
			</WindowCard>

			<br />

			<WindowCard
				title="Gift Vouchers"
				subtitle="Vouchers which have been pre-paid for and allocated onto the clients account, will be applied as a payment.">
				<div className="gift-vouchers">
					{!fetchingVouchers &&
						vouchers.length > 0 &&
						vouchers.map((voucher, index) => (
							<div
								key={index}
								className={["voucher", voucher.bookingID ? "not-available" : ""].join(" ")}
								onClick={() => useVoucher(voucher.id)}>
								<img
									src={MiniVoucher}
									alt="Voucher"
								/>

								<div className="voucher-details">
									<p>£{voucher.value}</p>

									{voucher.status === "PENDING" && (
										<Badge
											type="NEGATIVE"
											label="Not paid"
										/>
									)}

									{voucher.status === "REDEEMED" && (
										<Badge
											type="POSITIVE"
											label="Redeemed"
										/>
									)}

									{voucher.status === "PAID" && (
										<Badge
											type="INFO"
											label="Available"
										/>
									)}
								</div>
							</div>
						))}
				</div>

				{!fetchingVouchers && vouchers.length === 0 && <small className="no-vouchers">No gift vouchers available.</small>}

				{fetchingVouchers && (
					<div className="vouchers-loading-wrapper">
						<p>Loading...</p>
						<div className="svg-loading-wrap">
							<LoadingIcon />
						</div>
					</div>
				)}
			</WindowCard>
		</Window>
	)
}
