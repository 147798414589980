import { useEffect, useState, useContext } from "react"
import { NavLink } from "react-router-dom"
import { sendEmail } from "../../../utils/mail"
import { AlertsContext } from "../../../utils/providers/alerts"
import * as mailTypes from "../../../utils/exports/mail-types"
import { db, auth, storage } from "../../../utils/firebase"
import firebase from "firebase"
import moment from "moment"

// UI components
import Button from "../../../components/ui/button/button"
import Badge from "../../../components/ui/badge/badge"

// PDF generation
import fontkit from "@pdf-lib/fontkit"
import { PDFDocument } from "pdf-lib"
import { rgb } from "pdf-lib"

// Returns a table row for a voucher, with the ability to generate a PDF voucher
export default function VoucherRow({ id, purchased, purchased_by, purchased_for, issued_by, reference, value, status, bookingID }) {
	const [issuedStaff, setIssuedStaff] = useState({})
	const [byClient, setByClient] = useState({})
	const [forClient, setForClient] = useState({})
	const [booking, setBooking] = useState({})
	const [document, setDocument] = useState({})
	const [generating, setGenerating] = useState(false)
	const [sending, setSending] = useState(false)

	const purchasedString = moment(purchased.seconds, "X").format("MMM D[,] YYYY [at] HH:mm")
	const issuedDate = moment(purchased.seconds, "X").format("MMM D[,] YYYY")

	// Pull the alerts in for showing in the DOM
	const { pushAlert } = useContext(AlertsContext)

	const formattedPrice = new Intl.NumberFormat("en-GB", {
		style: "currency",
		currency: "GBP",
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	}).format(value)

	useEffect(() => {
		db.doc(`clients/${purchased_by}`)
			.get()
			.then((clientDoc) => {
				if (clientDoc.exists) {
					setByClient(clientDoc.data())
				}
			})

		db.doc(`clients/${purchased_for}`)
			.get()
			.then((clientDoc) => {
				if (clientDoc.exists) {
					setForClient(clientDoc.data())
				}
			})

		if (bookingID) {
			db.doc(`bookings/${bookingID}`)
				.get()
				.then((bookingDoc) => {
					if (bookingDoc.exists) {
						setBooking(bookingDoc.data())
					}
				})
		}

		if (issued_by) {
			db.doc(`users/${issued_by}`)
				.get()
				.then((staffDoc) => {
					if (staffDoc.exists) {
						setIssuedStaff(staffDoc.data())
					}
				})
		}

		db.doc(`gift_vouchers/${id}/documents/${reference}`)
			.get()
			.then((doc) => {
				if (doc.exists) {
					setDocument(doc.data())
				}
			})
	}, [])

	// Generate the voucher PDF that can be sent to the client
	const generateVoucher = async () => {
		// Toggle the loading state
		setGenerating(true)

		try {
			// Base URL for the original template PDF
			const baseTemplate =
				"https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/_system%2Fdocuments%2Fvouchers%2FVoucher%20Template%20-%2002-12-24.pdf?alt=media&token=a84f729a-2281-4465-bacd-e5cc79451b10"

			// Fetch this template as an array buffer
			const templatePDFBytes = await fetch(baseTemplate).then((response) => response.arrayBuffer())

			// Load the document up within the pdf-ib library
			const quotePDFDocument = await PDFDocument.load(templatePDFBytes)

			// Register the font kit with the PDF library
			quotePDFDocument.registerFontkit(fontkit)

			// Download a buffer version of the poppins font
			const poppins600Bytes = await fetch(
				"https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/_system%2Ffonts%2FPoppins-SemiBold.otf?alt=media&token=71d97025-3d30-4a6c-b662-4e7fdf051350"
			).then((res) => res.arrayBuffer())
			const poppins700Bytes = await fetch(
				"https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/_system%2Ffonts%2FPoppins-Bold.otf?alt=media&token=f0a366b3-0378-4b9b-acc7-a6755ec44733"
			).then((res) => res.arrayBuffer())

			// Embed the different fonts into the PDF
			const poppins600 = await quotePDFDocument.embedFont(poppins600Bytes)
			const poppins700 = await quotePDFDocument.embedFont(poppins700Bytes)

			// Pull out the various pages on the PDF
			const voucherTemplate = quotePDFDocument.getPage(0)

			// Setup some height/width variables for the actual page size
			const { height } = voucherTemplate.getSize()

			// Calculate the full width of the text
			const valueArr = [{ text: `£${value}`, font: poppins700 }]
			const valueWidth = valueArr.reduce((acc, part) => acc + part.font.widthOfTextAtSize(part.text, 52), 0)
			const valueOffset = valueWidth / 2

			// Write this part onto the page
			quotePDFDocument.getPage(0).drawText(`£${value}`, {
				x: 83.25 - valueOffset,
				y: height - 109,
				size: 52,
				font: valueArr[0].font,
				color: rgb(0, 0, 0),
			})

			// Calculate the full width of the text
			const referenceArr = [{ text: reference, font: poppins600 }]
			const referenceWidth = referenceArr.reduce((acc, part) => acc + part.font.widthOfTextAtSize(part.text, 9.5), 0)
			const referenceOffset = referenceWidth / 2

			// Write this part onto the page
			quotePDFDocument.getPage(0).drawText(reference, {
				x: 83.25 - referenceOffset,
				y: height - 172,
				size: 9.5,
				font: referenceArr[0].font,
				color: rgb(0, 0, 0),
			})

			// Calculate the full width of the text
			const issuedArr = [{ text: reference, font: poppins600 }]
			const issuedWidth = issuedArr.reduce((acc, part) => acc + part.font.widthOfTextAtSize(`Issued ${issuedDate}`, 6.5), 0)
			const issuedOffset = issuedWidth / 2

			// Write this part onto the page
			quotePDFDocument.getPage(0).drawText(`Issued ${issuedDate}`, {
				x: 83.25 - issuedOffset,
				y: height - 182.5,
				size: 6.5,
				font: issuedArr[0].font,
				color: rgb(0, 0, 0),
			})

			// Save the new PDF document
			const newPDFBytes = await quotePDFDocument.save()

			// Then we want to upload this into storage
			await uploadPDFVoucher(newPDFBytes)
		} catch (err) {
			console.log("Error generating voucher", err)
		} finally {
			setGenerating(false)
		}
	}

	// Handle the actual upload process of the file into the storage bucket
	const handleDocumentUpload = async (documentUploadRef) => {
		// Attach a listener onto the upload job to stream the progress into state
		const monitorUploadProgress = (upload) => {
			return new Promise((resolve, reject) => {
				// Setup the listener on the state_change variable for the upload
				upload.on(
					"state_changed",
					(snapshot) => {
						// Calculate a percentage for the upload progress
						const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
						console.log(`Uploading voucher document...${progress}%`)
					},
					(error) => console.log(error),
					() => {
						resolve()
					}
				)
			})
		}

		// Return a promise to halt execution until the upload is complete
		return new Promise((resolve, reject) => {
			// Attach the monitor to the uploaded file
			monitorUploadProgress(documentUploadRef).then(() => {
				// When it's complete, get the downloadURL from the callback
				documentUploadRef.snapshot.ref.getDownloadURL().then(async (url) => {
					// Write this document into the gift_vouchers 'documents' subcollection
					await db.doc(`gift_vouchers/${id}/documents/${reference}`).set({
						pdf_url: url,
						created: firebase.firestore.FieldValue.serverTimestamp(),
					})

					// Resolve the promise
					resolve(url)
				})
			})
		})
	}

	// Upload the PDF quote into storage
	const uploadPDFVoucher = async (PDFBytes) => {
		// Establish some metadata for the document
		const meta = {
			contentType: "application/pdf",
			customMetadata: {
				fileID: reference,
				uploadedBy: auth.currentUser.uid,
			},
		}

		// Upload the document into storage
		const upload = storage.ref(`gift_vouchers/${id}/${reference}`).put(PDFBytes, meta)

		// Wait for the document to be uploaded into the storage bucket
		const uploadedDocumentURL = await handleDocumentUpload(upload)

		// Set the state and update the package in the quote context
		setGenerating(false)
		setDocument({ pdf_url: uploadedDocumentURL })

		// Close any loading states out
		return true
	}

	// Sends an email to the client with the voucher attached
	const sendVoucher = async () => {
		// Return early if the document is missing
		if (!document?.pdf_url) return

		// Loading state
		setSending(true)

		try {
			// Download the voucher PDF as bytes
			const voucherPDFBytes = await fetch(document?.pdf_url).then((response) => response.arrayBuffer())

			// Convert the unit array data into a base64 string
			const unitArrayToBase64 = async (unitArrayData) => {
				// Use a FileReader to generate a base64 data URI
				const base64url = await new Promise((r) => {
					const reader = new FileReader()
					reader.onload = () => r(reader.result)
					reader.readAsDataURL(new Blob([unitArrayData]))
				})

				// Complete result would show: "data:application/octet-stream;base64,<the data>", so just
				// return the remainder of the string behind the meta at the start
				return base64url.split(",", 2)[1]
			}

			// Write the new document into a base64 for embedding onto the email
			const documentAsBase64 = await unitArrayToBase64(voucherPDFBytes)

			// Send the client email
			await sendEmail({
				template: mailTypes.GIFT_VOUCHER.template,
				subject: `Golf Holidays Direct Gift Voucher - Ref: ${reference}`,
				from: {
					name: "Golf Holidays Direct",
					email: "sales@golfholidaysdirect.com",
				},
				user: {
					name: `${byClient.first_name} ${byClient.last_name}`,
					email: byClient.email,
				},
				mail: {
					name: `${byClient.first_name} ${byClient.last_name}`,
					value: `£${value}`,
				},
				attachments: [
					{
						type: "application/pdf",
						name: `Golf Holidays Direct Gift Voucher - ${reference}.pdf`,
						content: documentAsBase64,
					},
				],
			})

			// Write a log onto the new client account for the quote send
			await db.collection(`clients/${purchased_by}/logs`).add({
				badge: "INFO",
				type: "VOUCHER_SENT",
				user: auth.currentUser.uid,
				message: `The gift voucher PDF (${reference}) was sent to ${byClient.email}`,
				created: firebase.firestore.FieldValue.serverTimestamp(),
			})

			// Notify that the voucher has been sent
			pushAlert({
				type: "SUCCESS",
				title: "Voucher email sent to the buyer.",
			})
		} catch (err) {
			console.log("Error sending voucher", err)
		} finally {
			setSending(false)
		}
	}

	return (
		<tr>
			<td>{purchasedString}</td>
			<td>
				{document?.pdf_url && (
					<a
						href={document?.pdf_url}
						target="_blank">
						{reference}
					</a>
				)}

				{!document?.pdf_url && <>{reference}</>}
			</td>
			<td>
				{issuedStaff?.first_name && (
					<>
						{issuedStaff.first_name} {issuedStaff.last_name}
					</>
				)}

				{!issuedStaff?.first_name && <small>Not issued internally</small>}
			</td>
			<td>
				{byClient.first_name && (
					<NavLink to={`/client/${purchased_by}`}>
						{byClient.first_name} {byClient.last_name}
					</NavLink>
				)}

				{!byClient.first_name && <small>Not bought directly</small>}
			</td>
			<td>
				<NavLink to={`/client/${purchased_for}`}>
					{forClient.first_name} {forClient.last_name}
				</NavLink>
			</td>
			<td>{formattedPrice}</td>
			<td>
				{status === "PENDING" && (
					<Badge
						type="NEGATIVE"
						label="Not paid"
					/>
				)}

				{status === "REDEEMED" && (
					<Badge
						type="POSITIVE"
						label="Redeemed"
					/>
				)}

				{status === "PAID" && (
					<Badge
						type="INFO"
						label="Paid & Available"
					/>
				)}
			</td>
			<td>
				{booking?.reference && <NavLink to={`/booking/${bookingID}`}>GHDB-{booking.reference}</NavLink>}
				{!booking?.reference && <small>Not used yet</small>}
			</td>
			<td className="is-button">
				<div style={{ display: "flex", justifyContent: "flex-end", width: "fit-content", marginLeft: "auto" }}>
					<Button
						small={true}
						label="Generate Voucher"
						loading={generating}
						loadingText="Generating..."
						onClick={() => generateVoucher()}
						disabled={document?.pdf_url}
					/>
					<Button
						small={true}
						label="Send Voucher (Buyer)"
						loading={sending}
						loadingText="Sending..."
						onClick={() => sendVoucher()}
						disabled={!document?.pdf_url || !byClient.email}
					/>
				</div>
			</td>
		</tr>
	)
}
