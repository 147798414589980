import React, { useEffect, useState } from "react"
import * as randomstring from "randomstring"
import { db, auth } from "../../utils/firebase"
import firebase from "firebase"
import "./gift-vouchers.scss"

// UI components
import Table from "../../components/structure/table/table"
import Title from "../../components/structure/title/title"
import Tile from "../../components/structure/tile/tile"
import Button from "../../components/ui/button/button"
import { LoadingIcon, PlusIcon } from "../../utils/svgs"
import VoucherRow from "./rows/voucher"
import Window from "../../components/structure/window/window"
import WindowCard from "../../components/ui/window-card/window-card"
import Input from "../../components/ui/inputs/input"
import Select from "../../components/ui/select/select"

// Returns the HTMl component for the Gift Vouchers
export default function GiftVouchers() {
	const [loading, setLoading] = useState(false)
	const [vouchers, setVouchers] = useState([])
	const [addingVoucher, setAddingVoucher] = useState(false)

	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [phone, setPhone] = useState("")
	const [email, setEmail] = useState("")
	const [value, setValue] = useState(null)
	const [creating, setCreating] = useState(false)

	useEffect(() => {
		fetchAndUpdateVouchers()
	}, [])

	const fetchAndUpdateVouchers = async () => {
		db.collection("gift_vouchers")
			.orderBy("purchased", "desc")
			.get()
			.then((voucherDocs) => {
				const vouchers = voucherDocs.docs.map((voucherDoc) => {
					const voucher = voucherDoc.data()
					return {
						...voucher,
						id: voucherDoc.id,
					}
				})
				setVouchers(vouchers)
				setLoading(false)
			})
	}

	const createVoucher = async () => {
		setCreating(true)

		try {
			const clientID = await db
				.collection("clients")
				.where("email", "==", email.toLowerCase())
				.get()
				.then(async (clientDocs) => {
					// If there is a client document found
					if (clientDocs.size > 0) {
						return clientDocs.docs[0].id
					} else {
						// Make a new client account for the "FROM" person
						const newAccountID = await db
							.collection("clients")
							.add({
								first_name: firstName.charAt(0).toUpperCase() + firstName.slice(1),
								last_name: lastName.charAt(0).toUpperCase() + lastName.slice(1),
								email: email.toLowerCase(),
								phone: phone,
								created: firebase.firestore.FieldValue.serverTimestamp(),
							})
							.then((newClientDoc) => {
								return newClientDoc.id
							})

						// Add a log for the client account creation
						await db.collection(`clients/${newAccountID}/logs`).add({
							type: "CLIENT_CREATED",
							message: "Client account was created",
							created: firebase.firestore.FieldValue.serverTimestamp(),
						})

						// Finally, return the ID
						return newAccountID
					}
				})

			// Generate a reference
			const voucherReference = randomstring.generate({
				length: 12,
				charset: "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
			})

			// Add a new gift voucher document to the database
			await db
				.collection("gift_vouchers")
				.add({
					purchased: firebase.firestore.FieldValue.serverTimestamp(),
					issued_by: auth.currentUser.uid,
					purchased_by: null,
					purchased_for: clientID,
					value: parseInt(value),
					reference: `GHDV-${voucherReference}`,
					status: "PAID",
					bookingID: null,
				})
				.then((newVoucherDoc) => {
					return newVoucherDoc.id
				})
		} catch (err) {
			console.log(err)
		} finally {
			fetchAndUpdateVouchers()

			setCreating(false)
			setAddingVoucher(false)
		}
	}

	return (
		<Tile fullPage={true}>
			<Title className="flex has-select-field">
				<h1>Gift Vouchers</h1>

				<Button
					label="Create voucher"
					small={true}
					icon={<PlusIcon />}
					onClick={() => setAddingVoucher(true)}
				/>
			</Title>

			<Table
				className="bookings-table"
				headings={["Date purchased/issued", "Reference", "Issued by", "Purchased by", "Purchased for", "Value", "Status", "Booking ref", ""]}
				noResults={vouchers.length === 0}
				noResultsMessage="No vouchers yet">
				{vouchers.map((voucher, index) => (
					<VoucherRow
						key={voucher.id}
						{...voucher}
					/>
				))}

				{loading && (
					<div className="table-loading-splash">
						<div className="loading-wrapper">
							<p>Loading...</p>
							<div className="svg-loading-wrap">
								<LoadingIcon />
							</div>
						</div>
					</div>
				)}
			</Table>

			{addingVoucher && (
				<Window
					title="Create a new voucher"
					close={() => setAddingVoucher(false)}>
					<WindowCard
						title="Client details"
						subtitle="Details for the client who will have this assigned to their account.">
						<div className="input-grid cols-2">
							<Input
								type="text"
								label="First name:"
								value={firstName}
								onChange={setFirstName}
								placeholder="First name:"
							/>
							<Input
								type="text"
								label="Last name:"
								value={lastName}
								onChange={setLastName}
								placeholder="Last name:"
							/>
							<Input
								type="tel"
								label="Phone:"
								value={phone}
								onChange={setPhone}
								placeholder="Phone:"
							/>
							<Input
								type="email"
								label="Email:"
								value={email}
								onChange={setEmail}
								placeholder="Email:"
							/>

							<div className="span-2">
								<Input
									value={value}
									onChange={setValue}
									symbol="£"
									placeholder="Voucher value:"
								/>
							</div>
						</div>
					</WindowCard>

					<Button
						label="Create voucher"
						className="window-card-button"
						onClick={() => createVoucher()}
						loading={creating}
						loadingText="Creating voucher..."
						disabled={!firstName || !lastName || !phone || !email || !value}
					/>
				</Window>
			)}
		</Tile>
	)
}
