import { auth, db } from "./firebase"
import { ROOM_TYPES } from "./exports/room-types"
import firebase from "firebase"
import moment from "moment"

/**
 * Mail imports
 */
import { sendEmail } from "./mail"
import * as mailTypes from "./exports/mail-types"

/**
 * Take in a bookingID along with a roundID to build the HTML needed to embed into the reservation email.
 */
export const sendTransferReservation = async (bookingID, hotelID) => {
	/**
	 * Get the booking details from the database
	 */
	const booking = await db
		.doc(`bookings/${bookingID}`)
		.get()
		.then((bookingDoc) => {
			/**
			 * Return a complete object of all booking details
			 */
			return { ...bookingDoc.data() }
		})
	/**
	 * Fetch the name of the booking agent
	 */
	const agent = await db
		.doc(`users/${booking.agent}`)
		.get()
		.then((agentDoc) => {
			/**
			 * Get the agent data
			 */
			const { first_name, last_name, email } = agentDoc.data()
			/**
			 * Return the data in an object
			 */
			return { name: `${first_name} ${last_name}`, email }
		})
	/**
	 * Get the client details
	 */
	const client = await db
		.doc(`clients/${booking.client}`)
		.get()
		.then((clientDoc) => {
			/**
			 * Return a complete object of all the client details
			 */
			return { ...clientDoc.data() }
		})
	/**
	 * Get the name of the hotel
	 */
	const hotel = await db
		.doc(`bookings/${bookingID}/hotels/${hotelID}`)
		.get()
		.then(async (hotelDoc) => {
			/**
			 * Get the resort ID for the hotel
			 */
			const { resortID } = hotelDoc.data()
			/**
			 * Get the hotel document from the database to reutnr the resort name
			 */
			const hotel_name = await db
				.doc(`hotels/${resortID}`)
				.get()
				.then((hotelDoc) => {
					/**
					 * Return the name for the hotel
					 */
					return hotelDoc.data().name
				})
			/**
			 * Return all hotel data along with the resort name
			 */
			return { ...hotelDoc.data(), hotel_name }
		})
	/**
	 * Get the transfer details
	 */
	const transfer = await db
		.doc(`transfers/${booking.transfer}`)
		.get()
		.then((transferDoc) => {
			/**
			 * Return a complete object of all the transfer partner details
			 */
			return { ...transferDoc.data() }
		})
	/**
	 * Get the group size by adding both golfers and non golfers
	 */
	const group_size = Number(booking.golfers + booking.non_golfers)
	/**
	 * Get the check in date
	 */
	const check_in = moment(hotel.check_in?.seconds, "X").format("DD/MM/YYYY")
	/**
	 * Get the departure date by adding the nights to the original check in date
	 */
	const departure = moment(hotel.check_in?.seconds, "X").add(hotel.nights, "days").format("DD/MM/YYYY")
	/**
	 * Setup an array to store the html content for the email
	 */
	let html_array = []
	/**
	 * Email intro
	 */
	html_array.push("<p>Dear partner,<br />Golf Holidays Direct would like to make the following booking:</p><br/>")
	/**
	 * Setup the table
	 */
	html_array.push("<table>")
	html_array.push("<tbody>")
	/**
	 * Has a booking for this transfer already been sent?
	 */
	if (booking?.transfer_booking_status) {
		/**
		 * Add a booking
		 */
		html_array.push("<tr>")
		html_array.push("<td colspan='2' style='color:red;'><strong>THIS IS A BOOKING AMENDMENT</strong></td>")
		html_array.push("</tr>")
	}
	/**
	 * Add the client name
	 */
	html_array.push("<tr>")
	html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Client name:</td>")
	html_array.push(`<td>${client?.first_name} ${client?.last_name}</td>`)
	html_array.push("</tr>")
	/**
	 * Add the group size
	 */
	html_array.push("<tr>")
	html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Group size:</td>")
	html_array.push(`<td>${group_size}</td>`)
	html_array.push("</tr>")
	/**
	 * Add the hotel name
	 */
	html_array.push("<tr>")
	html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Hotel name:</td>")
	html_array.push(`<td>${hotel.hotel_name}</td>`)
	html_array.push("</tr>")
	/**
	 * Add the arrival date
	 */
	html_array.push("<tr>")
	html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Arrival:</td>")
	html_array.push(`<td>${check_in}</td>`)
	html_array.push("</tr>")
	/**
	 * Add the depart date
	 */
	html_array.push("<tr>")
	html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Departing:</td>")
	html_array.push(`<td>${departure}</td>`)
	html_array.push("</tr>")
	/**
	 * Add the comments
	 */
	html_array.push("<tr>")
	html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Comments:</td>")
	html_array.push(`<td><pre style='font-family:Roboto,RobotoDraft,Helvetica,Arial,sans-serif;'>${booking.transfer_notes}</pre></td>`)
	html_array.push("</tr>")
	/**
	 * Close the table out
	 */
	html_array.push("</tbody>")
	html_array.push("</table>")
	/**
	 * Add in some additional information
	 */
	html_array.push("<p><strong>Please can you kindly email back to confirm the reservation.</strong></p>")
	/**
	 * Email closure
	 */
	html_array.push("<p>Kind regards,<br />Golf Holidays Direct</p>")
	/**
	 * Send the reservation email
	 */
	await sendEmail({
		subject: `Transfer request to ${hotel.hotel_name} on ${check_in}`,
		from: {
			name: agent.name,
			email: agent.email,
		},
		user: {
			name: transfer.name,
			email: transfer.email,
		},
		mail: {
			custom_html: `${html_array.join("")}`,
		},
		html: `${html_array.join("")}`,
	})
	/**
	 * Then we need to update the transfer booking to reflect the booking has been sent
	 */
	await db.doc(`bookings/${bookingID}`).set(
		{
			transfer_booking_status: "sent",
		},
		{ merge: true }
	)
	/**
	 * Add a log to the booking to record the email being sent
	 */
	await db.collection(`bookings/${bookingID}/logs`).add({
		type: "TRANSFER_BOOKING_EMAIL",
		badge: "INFO",
		message: `Transfer booking email sent to ${transfer?.email}`,
		created: firebase.firestore.FieldValue.serverTimestamp(),
		user: auth.currentUser.uid,
	})
}

/**
 * Take in a bookingID along with a roundID to build the HTML needed to embed into the reservation email.
 */
export const sendRoundReservation = async (bookingID, roundID) => {
	/**
	 * Get the booking details from the database
	 */
	const booking = await db
		.doc(`bookings/${bookingID}`)
		.get()
		.then((bookingDoc) => {
			/**
			 * Return a complete object of all booking details
			 */
			return { ...bookingDoc.data() }
		})
	/**
	 * Fetch the name of the booking agent
	 */
	const agent = await db
		.doc(`users/${booking.agent}`)
		.get()
		.then((agentDoc) => {
			/**
			 * Get the agent data
			 */
			const { first_name, last_name, email } = agentDoc.data()
			/**
			 * Return the data in an object
			 */
			return { name: `${first_name} ${last_name}`, email }
		})
	/**
	 * Get the client details
	 */
	const client = await db
		.doc(`clients/${booking.client}`)
		.get()
		.then((clientDoc) => {
			/**
			 * Return a complete object of all the client details
			 */
			return { ...clientDoc.data() }
		})
	/**
	 * Fetch the details of the round
	 */
	const round_data = await db
		.doc(`bookings/${bookingID}/rounds/${roundID}`)
		.get()
		.then(async (roundDoc) => {
			/**
			 * Get the courseID for the current round
			 */
			const { courseID } = roundDoc.data()
			/**
			 * Fetch the course document from the database
			 */
			const course = await db
				.doc(`courses/${courseID}`)
				.get()
				.then((courseDoc) => {
					/**
					 * Deconstruct the resort data from the document
					 */
					const { name, email } = courseDoc.data()
					/**
					 * Return all the course data
					 */
					return { course_name: name, course_email: email }
				})
			/**
			 * Return the round data along with the details for the course
			 */
			return { ...roundDoc.data(), ...course }
		})
	/**
	 * Set a new array for storing the email html
	 */
	let html_array = []
	/**
	 * Email intro
	 */
	html_array.push("<p>Dear partner,<br />Golf Holidays Direct would like to make the following booking:</p>")
	/**
	 * Keep a record of roundIDs to update once the booking has been sent
	 */
	let roundIDs = []
	/**
	 * Loop through each of the rounds
	 */
	await Promise.all(
		booking.rounds_order?.map(async (round) => {
			/**
			 * Fetch the details of the round
			 */
			const checked_round_data = await db
				.doc(`bookings/${bookingID}/rounds/${round}`)
				.get()
				.then(async (roundDoc) => {
					/**
					 * Get the courseID for the current round
					 */
					const { courseID } = roundDoc.data()
					/**
					 * Fetch the course document from the database
					 */
					const course = await db
						.doc(`courses/${courseID}`)
						.get()
						.then((courseDoc) => {
							/**
							 * Deconstruct the resort data from the document
							 */
							const { name, email } = courseDoc.data()
							/**
							 * Return all the course data
							 */
							return { course_name: name, course_email: email }
						})
					/**
					 * Return the round data along with the details for the course
					 */
					return { ...roundDoc.data(), ...course }
				})
			/**
			 * Does the resort paired with this course share the same email address as the hotel?
			 */
			if (round_data.course_email === checked_round_data.course_email) {
				/**
				 * Push the ID into the array for updating later
				 */
				roundIDs.push(round)
				/**
				 * Setup the start of the table html
				 */
				html_array.push("<table>")
				html_array.push("<tbody>")
				/**
				 * Add in an empty table row for some space
				 */
				html_array.push("<br />")
				/**
				 * Does this round already have a booking status
				 */
				if (checked_round_data?.booking_status) {
					/**
					 * Add a booking
					 */
					html_array.push("<tr>")
					html_array.push("<td colspan='2' style='color:red;'><strong>THIS IS A BOOKING AMENDMENT</strong></td>")
					html_array.push("</tr>")
				}
				/**
				 * Add in a title for the round
				 */
				html_array.push("<tr><td colspan='2'><strong>Round booking:</strong></td></tr>")
				/**
				 * Add the client name
				 */
				html_array.push("<tr>")
				html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Client name:</td>")
				html_array.push(`<td>${client?.first_name} ${client?.last_name}</td>`)
				html_array.push("</tr>")
				/**
				 * Add the group size
				 */
				html_array.push("<tr>")
				html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Group size:</td>")
				html_array.push(`<td>${booking.golfers}</td>`)
				html_array.push("</tr>")
				/**
				 * Add the course name
				 */
				html_array.push("<tr>")
				html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Course:</td>")
				html_array.push(`<td>${checked_round_data.course_name}</td>`)
				html_array.push("</tr>")
				/**
				 * Generate a date string for the tee time
				 */
				const tee_time_datetime = moment(checked_round_data.tee_time.seconds, "X").format("DD/MM/YYYY HH:mm")
				/**
				 * Add the tee time
				 */
				html_array.push("<tr>")
				html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Tee time:</td>")
				html_array.push(`<td>${tee_time_datetime}</td>`)
				html_array.push("</tr>")

				// If there is a value for the new comprehensive buggies field
				if (checked_round_data.comp_buggies && checked_round_data.comp_buggies !== "false" && checked_round_data.comp_buggies !== "true") {
					html_array.push("<tr>")
					html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Buggies:</td>")

					// Find the relevant text needed to send to the resort
					let buggiesText = ""
					switch (checked_round_data.comp_buggies) {
						case "yes_reserved":
							buggiesText = "Please reserve this group buggies for them to make payment on arrival."
							break
						case "yes_greenfee":
							buggiesText = "Please reserve this group buggies, the cost is included in the green fee to be paid for by Golf Holidays Direct."
							break
						case "yes_prepaid":
							buggiesText = "Please reserve this group buggies, payment will be made directly by Golf Holidays Direct."
							break
						default:
							buggiesText = "No buggies required."
					}

					html_array.push(`<td>${buggiesText}</td>`)
					html_array.push("</tr>")
				} else {
					// Defaulting to the old buggies line
					html_array.push("<tr>")
					html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Buggies:</td>")
					html_array.push(`<td>${checked_round_data.buggies ? "Yes" : "No"}</td>`)
					html_array.push("</tr>")
				}

				/**
				 * Add in the comments
				 */
				html_array.push("<tr>")
				html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Comments:</td>")
				html_array.push(`<td><pre style='font-family:Roboto,RobotoDraft,Helvetica,Arial,sans-serif;'>${checked_round_data.comments || "No additional comments"}</pre></td>`)
				html_array.push("</tr>")
				/**
				 * Close out the table
				 */
				html_array.push("</tbody>")
				html_array.push("</table>")
				/**
				 * Add in some additional information
				 */
				html_array.push("<p><strong>Please can you kindly email back to confirm the reservation.</strong></p>")
			}
		})
	)
	/**
	 * Email closure
	 */
	html_array.push("<p>Kind regards,<br />Golf Holidays Direct</p>")
	/**
	 * Send the reservation email
	 */
	await sendEmail({
		// template: mailTypes.BOOKING_BOILERPLATE.template,
		subject: `Tee booking request for ${round_data.course_name}`,
		from: {
			name: agent.name,
			email: agent.email,
		},
		user: {
			name: round_data.course_name,
			email: round_data.course_email,
		},
		mail: {
			custom_html: `${html_array.join("")}`,
		},
		html: `${html_array.join("")}`,
	})
	/**
	 * When the email has sent successfully, we need to update the status for the rounds
	 */
	await Promise.all(
		roundIDs.map(async (booked_round) => {
			/**
			 * Update the round booking status too
			 */
			await db.doc(`bookings/${bookingID}/rounds/${booked_round}`).set(
				{
					booking_status: "sent",
				},
				{ merge: true }
			)
			/**
			 * Add a log into the booking emails to record the round booking too
			 */
			await db.collection(`bookings/${bookingID}/logs`).add({
				type: "ROUND_BOOKING_EMAIL",
				badge: "INFO",
				message: `Round booking email sent to ${round_data.course_email}`,
				created: firebase.firestore.FieldValue.serverTimestamp(),
				user: auth.currentUser.uid,
			})
		})
	)
}

/**
 * Take in a bookingID along with a hotelID to build the HTML needed to embed into the reservation email.
 *
 * Before the email is sent, we'll also loop through all the rounds associated with the booking and check
 * to see if the matches the email for the hotel, if it does we need to build the HTML for the round
 * reservation too and add it to the HTML that is returned.
 */
export const sendHotelAndRoundsReservation = async (bookingID, hotelID) => {
	/**
	 * Get the booking details from the database
	 */
	const booking = await db
		.doc(`bookings/${bookingID}`)
		.get()
		.then((bookingDoc) => {
			/**
			 * Return a complete object of all booking details
			 */
			return { ...bookingDoc.data() }
		})
	/**
	 * Get the client details
	 */
	const client = await db
		.doc(`clients/${booking.client}`)
		.get()
		.then((clientDoc) => {
			/**
			 * Return a complete object of all the client details
			 */
			return { ...clientDoc.data() }
		})
	/**
	 * Get the group size by adding both golfers and non golfers
	 */
	const group_size = Number(booking.golfers + booking.non_golfers)
	/**
	 * Generate a date from the date_from timestamp
	 */
	let check_in_date = null
	/**
	 * Fetch the name of the booking agent
	 */
	const agent = await db
		.doc(`users/${booking.agent}`)
		.get()
		.then((agentDoc) => {
			/**
			 * Get the agent data
			 */
			const { first_name, last_name, email } = agentDoc.data()
			/**
			 * Return the data in an object
			 */
			return { name: `${first_name} ${last_name}`, email }
		})
	/**
	 * Fetch the details of the hotel
	 */
	const hotel = await db
		.doc(`bookings/${bookingID}/hotels/${hotelID}`)
		.get()
		.then(async (hotelDoc) => {
			/**
			 * Deconstruct the hotel data from the document on the booking
			 */
			const { resortID, check_in } = hotelDoc.data()
			/**
			 * Fetch the data for this resort (like their name and email)
			 */
			const resort = await db
				.doc(`hotels/${resortID}`)
				.get()
				.then((resortDoc) => {
					/**
					 * Deconstruct the resort data from the document
					 */
					const { name, email, currency } = resortDoc.data()

					/*
					 * Format the date for the emails
					 */
					check_in_date = moment(check_in.seconds, "X").format("DD/MM/YYYY")

					/**
					 * Return an object with the name and email for the resort
					 */
					return { resort_name: name, resort_email: email, resort_currency: currency }
				})
			/**
			 * Return an object with all the relevant hotel data
			 */
			return { ...hotelDoc.data(), ...resort }
		})
	/**
	 * Set a new array for storing the email html
	 */
	let html_array = []
	/**
	 * Email intro
	 */
	html_array.push("<p>Dear partner,<br />Golf Holidays Direct would like to make the following bookings:</p><br/>")
	/**
	 * Setup the start of the table html
	 */
	html_array.push("<table>")
	html_array.push("<tbody>")
	/**
	 * Firstly, we need to see if a reservation email has already been sent to the hotel
	 */
	if (hotel?.booking_status) {
		/**
		 * Add a booking
		 */
		html_array.push("<tr>")
		html_array.push("<td colspan='2' style='color:red;'><strong>THIS IS A BOOKING AMENDMENT</strong></td>")
		html_array.push("</tr>")
	}
	/**
	 * Add in a title for the hotel
	 */
	html_array.push("<tr><td colspan='2'><strong>Hotel booking:</strong></td></tr>")
	/**
	 * Add the client name
	 */
	html_array.push("<tr>")
	html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Client name:</td>")
	html_array.push(`<td>${client?.first_name} ${client?.last_name}</td>`)
	html_array.push("</tr>")
	/**
	 * Add the group size
	 */
	html_array.push("<tr>")
	html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Group size:</td>")
	html_array.push(`<td>${group_size}</td>`)
	html_array.push("</tr>")
	/**
	 * Add the hotel name
	 */
	html_array.push("<tr>")
	html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Hotel name:</td>")
	html_array.push(`<td>${hotel.resort_name}</td>`)
	html_array.push("</tr>")
	/**
	 * Add the arrival date
	 */
	html_array.push("<tr>")
	html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Arrival:</td>")
	html_array.push(`<td>${check_in_date}</td>`)
	html_array.push("</tr>")
	/**
	 * Add the nights
	 */
	html_array.push("<tr>")
	html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Nights:</td>")
	html_array.push(`<td>${hotel.nights}</td>`)
	html_array.push("</tr>")
	/**
	 * Add the hotel cost
	 */
	html_array.push("<tr>")
	html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Cost:</td>")
	html_array.push("<td>")
	html_array.push(`${hotel.resort_currency === "usd" ? "$" : hotel.resort_currency === "eur" ? "€" : "£"}`)
	html_array.push(`${hotel.total_cost ? hotel.total_cost.toLocaleString() : ""}`)
	html_array.push("</td>")
	html_array.push("</tr>")
	/**
	 * Add the rooms
	 */
	html_array.push("<tr>")
	html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Requested rooms:</td>")
	/**
	 * Setup an array to store the various room types
	 */
	let room_options = {}
	/**
	 * Loop through the rooms on the hotel document
	 */
	hotel.rooms.forEach((room) => {
		/**
		 * Either increment the count by 1 or set it to 1 if it doesn't exist yet
		 */
		room_options[ROOM_TYPES[room.room_type]] = room_options[ROOM_TYPES[room.room_type]] + 1 || 1
	})
	/**
	 * Setup a new array for storing the room types
	 */
	let rooms_string = []
	/**
	 * Loop through the rooms on the hotel document
	 */
	for (const [room, count] of Object.entries(room_options)) {
		rooms_string.push(`${count} x ${room} <br />`)
	}
	html_array.push(`<td>${rooms_string.join("")}</td>`)
	html_array.push("</tr>")
	/**
	 * Add the comments
	 */
	html_array.push("<tr>")
	html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Comments:</td>")
	html_array.push(`<td><pre style='font-family:Roboto,RobotoDraft,Helvetica,Arial,sans-serif;'>${hotel.comments || "No additional comments"}</pre></td>`)
	html_array.push("</tr>")
	/**
	 * Close out the table
	 */
	html_array.push("</tbody>")
	html_array.push("</table>")
	/**
	 * Keep a record of roundIDs to update once the booking has been sent
	 */
	let roundIDs = []
	/**
	 * Are there any rounds in the order array to add into the booking?
	 */
	if (booking.rounds_order && booking.rounds_order.length > 0) {
		/**
		 * Loop through each of the rounds
		 */
		await Promise.all(
			booking.rounds_order?.map(async (round) => {
				/**
				 * Fetch the details of the round
				 */
				const round_data = await db
					.doc(`bookings/${bookingID}/rounds/${round}`)
					.get()
					.then(async (roundDoc) => {
						/**
						 * Get the courseID for the current round
						 */
						const { courseID } = roundDoc.data()
						/**
						 * Fetch the course document from the database
						 */
						const course = await db
							.doc(`courses/${courseID}`)
							.get()
							.then((courseDoc) => {
								/**
								 * Deconstruct the resort data from the document
								 */
								const { name, email } = courseDoc.data()
								/**
								 * Return all the course data
								 */
								return { course_name: name, course_email: email }
							})
						/**
						 * Return the round data along with the details for the course
						 */
						return { ...roundDoc.data(), ...course }
					})
				/**
				 * Does the resort paired with this course share the same email address as the hotel?
				 */
				if (hotel.resort_email === round_data.course_email) {
					/**
					 * Push the ID into the array for updating later
					 */
					roundIDs.push(round)
					/**
					 * Setup the start of the table html
					 */
					html_array.push("<table>")
					html_array.push("<tbody>")
					/**
					 * Add in an empty table row for some space
					 */
					html_array.push("<br />")
					/**
					 * Does this round already have a booking status
					 */
					if (round_data?.booking_status) {
						/**
						 * Add a booking
						 */
						html_array.push("<tr>")
						html_array.push("<td colspan='2' style='color:red;'><strong>THIS IS A BOOKING AMENDMENT</strong></td>")
						html_array.push("</tr>")
					}
					/**
					 * Add in a title for the round
					 */
					html_array.push("<tr><td colspan='2'><strong>Round booking:</strong></td></tr>")
					/**
					 * Add the client name
					 */
					html_array.push("<tr>")
					html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Client name:</td>")
					html_array.push(`<td>${client?.first_name} ${client?.last_name}</td>`)
					html_array.push("</tr>")
					/**
					 * Add the group size
					 */
					html_array.push("<tr>")
					html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Group size:</td>")
					html_array.push(`<td>${booking.golfers}</td>`)
					html_array.push("</tr>")
					/**
					 * Add the course name
					 */
					html_array.push("<tr>")
					html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Course:</td>")
					html_array.push(`<td>${round_data.course_name}</td>`)
					html_array.push("</tr>")
					/**
					 * Generate a date string for the tee time
					 */
					const tee_time_datetime = moment(round_data.tee_time.seconds, "X").format("DD/MM/YYYY HH:mm")
					/**
					 * Add the tee time
					 */
					html_array.push("<tr>")
					html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Tee time:</td>")
					html_array.push(`<td>${tee_time_datetime}</td>`)
					html_array.push("</tr>")
					// If there is a value for the new comprehensive buggies field
					if (round_data.comp_buggies && round_data.comp_buggies !== "false" && round_data.comp_buggies !== "true") {
						html_array.push("<tr>")
						html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Buggies:</td>")

						// Find the relevant text needed to send to the resort
						let buggiesText = ""
						switch (round_data.comp_buggies) {
							case "yes_reserved":
								buggiesText = "Please reserve this group buggies for them to make payment on arrival."
								break
							case "yes_greenfee":
								buggiesText = "Please reserve this group buggies, the cost is included in the green fee to be paid for by Golf Holidays Direct."
								break
							case "yes_prepaid":
								buggiesText = "Please reserve this group buggies, payment will be made directly by Golf Holidays Direct."
								break
							default:
								buggiesText = "No buggies required."
						}

						html_array.push(`<td>${buggiesText}</td>`)
						html_array.push("</tr>")
					} else {
						// Defaulting to the old buggies line
						html_array.push("<tr>")
						html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Buggies:</td>")
						html_array.push(`<td>${round_data.buggies ? "Yes" : "No"}</td>`)
						html_array.push("</tr>")
					}
					/**
					 * Add in the comments
					 */
					html_array.push("<tr>")
					html_array.push("<td style='padding-right:25px;white-space:nowrap;'>Comments:</td>")
					html_array.push(`<td><pre style='font-family:Roboto,RobotoDraft,Helvetica,Arial,sans-serif;'>${round_data.comments || "No additional comments"}</pre></td>`)
					html_array.push("</tr>")
					/**
					 * Close out the table
					 */
					html_array.push("</tbody>")
					html_array.push("</table>")
					/**
					 * Add in some additional information
					 */
					html_array.push("<p><strong>Please can you kindly email back to confirm the reservation.</strong></p>")
				}
			})
		)
	}
	/**
	 * Email closure
	 */
	html_array.push("<p>Kind regards,<br />Golf Holidays Direct</p>")
	/**
	 * Send the reservation email
	 */
	await sendEmail({
		// template: mailTypes.BOOKING_BOILERPLATE.template,
		subject: `Booking request for ${check_in_date}`,
		from: {
			name: agent.name,
			email: agent.email,
		},
		user: {
			name: hotel.resort_name,
			email: hotel.resort_email,
		},
		mail: {
			custom_html: `${html_array.join("")}`,
		},
		html: `${html_array.join("")}`,
	})
	/**
	 * When the email has sent successfully, we need to update the status for the hotel
	 */
	await db.doc(`bookings/${bookingID}/hotels/${hotelID}`).set(
		{
			booking_status: "sent",
		},
		{ merge: true }
	)
	/**
	 * Add a log into the booking emails to record the hotel booking
	 */
	await db.collection(`bookings/${bookingID}/logs`).add({
		type: "HOTEL_BOOKING_EMAIL",
		badge: "INFO",
		message: `Hotel booking email sent to ${hotel.resort_email}`,
		created: firebase.firestore.FieldValue.serverTimestamp(),
		user: auth.currentUser.uid,
	})
	/**
	 * Loop through any rounds that were found too
	 */
	await Promise.all(
		roundIDs.map(async (booked_round) => {
			/**
			 * Update the round booking status too
			 */
			await db.doc(`bookings/${bookingID}/rounds/${booked_round}`).set(
				{
					booking_status: "sent",
				},
				{ merge: true }
			)
			/**
			 * Add a log into the booking emails to record the round booking too
			 */
			await db.collection(`bookings/${bookingID}/logs`).add({
				type: "ROUND_BOOKING_EMAIL",
				badge: "INFO",
				message: `Round booking email sent to ${hotel.resort_email}`,
				created: firebase.firestore.FieldValue.serverTimestamp(),
				user: auth.currentUser.uid,
			})
		})
	)
}
