import { useEffect, useState, createContext } from "react"

// Create the context for the quote
const QuoteContext = createContext()

// Return a provider for the given context
const QuoteProvider = ({ children }) => {
	const [ctxSelectedOptions, setCtxSelectedOptions] = useState([])
	const [ctxSelectedPackages, setCtxSelectedPackages] = useState([])
	const [ctxTotalCost, setCtxTotalCost] = useState(0)
	const [ctxFinalCost, setCtxFinalCost] = useState(0)
	const [ctxFinalConvertedCost, setCtxFinalConvertedCost] = useState(0)
	const [ctxSaleProfit, setCtxSaleProfit] = useState(0)
	const [ctxAgentCommission, setCtxAgentCommission] = useState(0)
	const [ctxSaleMarkup, setCtxSaleMarkup] = useState(0)
	const [ctxSaleAdjustedMarkup, setCtxSaleAdjustedMarkup] = useState(0)
	const [ctxIncludedDates, setCtxIncludedDates] = useState([])
	const [ctxGroupSize, setCtxGroupSize] = useState(1)
	const [ctxDepositValue, setCtxDepositValue] = useState(50)

	// Currency & cost fields
	const [ctxExchangeRate, setCtxExchangeRate] = useState(1)
	const [ctxConvertedBase, setCtxConvertedBase] = useState(0)
	const [ctxSellAtCost, setCtxSellAtCost] = useState(0)

	// Ad-hoc charges
	const [ctxIncluded, setCtxIncluded] = useState({
		airport_transfers: false,
		golf_transfers: false,
		flights: false,
		euro_tunnel: false,
		car_hire: false,
	})
	const [ctxChargeAirportTransfer, setCtxChargeAirportTransfer] = useState(null)
	const [ctxChargeGolfTransfer, setCtxChargeGolfTransfer] = useState(null)
	const [ctxChargeFlights, setCtxChargeFlights] = useState(null)
	const [ctxChargeEuroTunnel, setCtxChargeEuroTunnel] = useState(null)
	const [ctxChargeCarHire, setCtxChargeCarHire] = useState(null)

	// Client details
	const [ctxClientID, setCtxClientID] = useState("")
	const [ctxClientFirstName, setCtxClientFirstName] = useState("")
	const [ctxClientLastName, setCtxClientLastName] = useState("")
	const [ctxClientEmail, setCtxClientEmail] = useState("")

	// Completed packages
	const [ctxCompletedPackages, setCtxCompletedPackages] = useState([])

	// When the packages are updated
	useEffect(() => {
		// Create a new array to store the included dates for the given quote
		let quoteDays = []

		// Map over each of the packages
		ctxSelectedPackages.forEach((selectedPackage) => {
			// Push the package dates into the array
			quoteDays.push(...selectedPackage.included_dates)
		})

		// Condense the array down to remove duplicates
		quoteDays = [...new Set(quoteDays)]

		// Find the package listed with the smallest group size
		const smallestGroupSize = ctxSelectedPackages.reduce((smallest, current) => {
			// Parse is as an integer
			const currentGroupSize = parseInt(current.group_size)

			// Make sure it's valid
			if (isNaN(currentGroupSize)) {
				return smallest
			}

			// Then return the smaller out of the comparision
			return currentGroupSize < smallest ? currentGroupSize : smallest
		}, Infinity)

		// Then set this value as the group size
		if (smallestGroupSize !== Infinity && smallestGroupSize > 0) {
			setCtxGroupSize(smallestGroupSize)
		}

		// Then set this array into the quote context
		setCtxIncludedDates(quoteDays)
	}, [ctxSelectedPackages])

	// When any of the package options are updated
	useEffect(() => {
		// Setup a running total
		let runningTotal = 0

		// if (!isNaN(parseFloat(ctxChargeFlights)) && ctxChargeFlights !== null && ctxIncluded.flights) {
		//     runningTotal += parseFloat(ctxChargeFlights)
		// }

		if (!isNaN(parseFloat(ctxChargeGolfTransfer)) && ctxChargeGolfTransfer !== null && ctxIncluded.golf_transfers) {
			runningTotal += parseFloat(ctxChargeGolfTransfer)
		}

		if (!isNaN(parseFloat(ctxChargeAirportTransfer)) && ctxChargeAirportTransfer !== null && ctxIncluded.airport_transfers) {
			runningTotal += parseFloat(ctxChargeAirportTransfer)
		}

		if (!isNaN(parseFloat(ctxChargeEuroTunnel)) && ctxChargeEuroTunnel !== null && ctxIncluded.euro_tunnel) {
			runningTotal += parseFloat(ctxChargeEuroTunnel)
		}

		if (!isNaN(parseFloat(ctxChargeCarHire)) && ctxChargeCarHire !== null && ctxIncluded.car_hire) {
			runningTotal += parseFloat(ctxChargeCarHire)
		}

		if (typeof ctxSelectedPackages !== "undefined" && ctxSelectedPackages.length > 0) {
			for (let index = 0; index < ctxSelectedPackages.length; index++) {
				if (!isNaN(parseFloat(ctxSelectedPackages[index].total_cost))) {
					runningTotal += ctxSelectedPackages[index].total_cost
				}
			}
		}

		// Set the running total into the state
		setCtxTotalCost(runningTotal)
	}, [ctxIncluded, ctxChargeGolfTransfer, ctxChargeAirportTransfer, ctxSelectedPackages, ctxChargeEuroTunnel, ctxChargeCarHire])

	// When the total cost, flights cost or exchange rate is updated
	useEffect(() => {
		// Make sure we have either an exchange rate or total cost present
		if (ctxTotalCost > 0 && ctxExchangeRate > 0) {
			// Mutliply the total cost given by the exchange rate input
			let convertedBase = Math.round(ctxTotalCost / ctxExchangeRate)

			// Are there any flights costs to be added on?
			if (!isNaN(parseFloat(ctxChargeFlights)) && ctxChargeFlights !== null && ctxIncluded.flights) {
				convertedBase += parseFloat(ctxChargeFlights)
			}

			// Then set this as the converted base
			setCtxConvertedBase(convertedBase)
			setCtxSellAtCost(convertedBase)
		}
	}, [ctxTotalCost, ctxChargeFlights, ctxExchangeRate])

	// When the sell at price is adjusted
	useEffect(() => {
		// Make sure the value we have is valid
		if (ctxSellAtCost >= 0) {
			// Subtract the actual cost from the converted base price
			const profit = ctxSellAtCost - ctxConvertedBase

			// Work out the profit percentage
			const profitPercentage = ((profit / ctxConvertedBase) * 100).toFixed(2)

			// Is the markup a valid float?
			if (!isNaN(parseFloat(profitPercentage))) {
				// Set this adjusted markup and final cost into the state
				setCtxSaleAdjustedMarkup(profitPercentage)
				setCtxFinalCost(ctxSellAtCost)
				setCtxSaleProfit(profit)

				// Then work out the agents commission
				const agentCommission = (profit * 0.05).toFixed(2)
				setCtxAgentCommission(agentCommission)
			} else {
				// Otherwise set the markups back to 0
				setCtxSaleAdjustedMarkup(0)
			}
		}
	}, [ctxSellAtCost])

	// When the agent margin has been updated
	useEffect(() => {
		// If there is no total cost available in the state yet
		if (!ctxTotalCost || ctxTotalCost === 0) {
			return
		}

		// Attempt to parse the newMargin value as a float
		const marginAsFloat = parseFloat(ctxSaleMarkup)

		// If we don't match the requirement
		if (!marginAsFloat || marginAsFloat === 0) {
			return
		}

		// Calculate the final cost with markup by adding the percentage to the current total
		const newBasePrice = ctxTotalCost * (1 + ctxSaleMarkup / 100)

		// Round the new price to the nearest £5 with no decimal places
		const roundedFinalPrice = Math.round(newBasePrice / 5) * 5
		const baseRemainder = roundedFinalPrice % 5
		const markupAdjustedFinalPrice = baseRemainder >= 2.5 ? roundedFinalPrice + (5 - baseRemainder) : roundedFinalPrice - baseRemainder

		// Calculate the difference between the new 'rounded' price and the 'original' base price
		const markup = markupAdjustedFinalPrice - ctxTotalCost

		// Calculate the percentage markup
		const percentageMarkup = (markup / ctxTotalCost) * 100

		// Parse the new markup to a maximum of 3 decimal places
		const parsedNewMarkup = Number(percentageMarkup.toFixed(3))

		// Set this adjusted markup into the state
		setCtxSaleAdjustedMarkup(parsedNewMarkup)
		setCtxFinalCost(markupAdjustedFinalPrice)

		// Then we want to work out what the profit on the quote is
		const saleProfit = markupAdjustedFinalPrice - ctxTotalCost
		setCtxSaleProfit(saleProfit)

		// Then also work out the agents commission
		const agentCommission = (saleProfit * 0.05).toFixed(2)
		setCtxAgentCommission(agentCommission)
	}, [ctxSaleMarkup, ctxTotalCost])

	// Update the packages array with either a new
	const ctxUpdatePackage = (packageDetails) => {
		// Get the ID from the current state array
		const packageID = packageDetails.id

		// Attempt to find the index for the given package already
		const packageIndex = ctxSelectedPackages.findIndex((packageEl) => packageEl.id === packageID)

		// Was it found in the array?
		if (packageIndex !== -1) {
			// Create a clone of the packages
			const packagesClone = [...ctxSelectedPackages]

			// Find the current package in the state
			const currentPackage = packagesClone[packageIndex]

			// If the two versions of the package do not match
			if (JSON.stringify(currentPackage) !== JSON.stringify(packageDetails)) {
				// Update the package with the matching index
				packagesClone[packageIndex] = packageDetails

				// Then update the state with this updated copy of the array
				setCtxSelectedPackages(packagesClone)
			}
		} else {
			// If it wasn't found, add it into the array like normal
			setCtxSelectedPackages([...ctxSelectedPackages, packageDetails])
		}
	}

	// Write a given PDF URL onto a completed package
	const ctxSavePDFLink = async (packageID, PDFUrl, PDFBytes) => {
		// Attempt to find the index for the completed package
		const packageIndex = ctxCompletedPackages.findIndex((packageEl) => packageEl._id === packageID)

		// Was it found in the array?
		if (packageIndex !== -1) {
			// Create a clone of the packages
			const packagesClone = [...ctxCompletedPackages]

			// Find the current package in the state
			packagesClone[packageIndex].pdf_url = PDFUrl
			packagesClone[packageIndex].pdf_bytes = PDFBytes

			// Then update the state with this updated copy of the array
			setCtxSelectedPackages(packagesClone)
		}
	}

	// Reset the entire working state back to defaults
	const ctxResetWorkingState = async () => {
		return new Promise((res, rej) => {
			setCtxSelectedOptions([])
			setCtxSelectedPackages([])
			setCtxTotalCost(0)
			setCtxFinalCost(0)
			setCtxFinalConvertedCost(0)
			setCtxConvertedBase(0)
			setCtxSellAtCost(0)
			setCtxSaleProfit(0)
			setCtxAgentCommission(0)
			setCtxSaleMarkup(0)
			setCtxSaleAdjustedMarkup(0)
			setCtxGroupSize(1)
			setCtxIncludedDates([])
			setCtxExchangeRate(1)
			setCtxIncluded({
				airport_transfers: false,
				golf_transfers: false,
				flights: false,
				euro_tunnel: false,
				car_hire: false,
			})
			setCtxChargeAirportTransfer(null)
			setCtxChargeGolfTransfer(null)
			setCtxChargeFlights(null)
			setCtxChargeEuroTunnel(null)
			setCtxChargeCarHire(null)
			setCtxDepositValue(50)
			setCtxClientID("")
			setCtxClientFirstName("")
			setCtxClientLastName("")
			setCtxClientEmail("")

			res(true)
		})
	}

	// Save the current complete package into the array and reset to the start
	const ctxSaveCompletePackage = async () => {
		// Generate a unique ID for this quote
		const generateID = () => {
			return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
				const r = (Math.random() * 16) | 0
				const v = c == "x" ? r : (r & 0x3) | 0x8
				return v.toString(16)
			})
		}

		// Generate a unique reference to be shown on the quotes
		const generateRef = () => {
			// Get a random capital letter or number
			const randomChar = () => {
				const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
				return chars.charAt(Math.floor(Math.random() * chars.length))
			}

			// Generate the random string in the format GHDQ-XXXXXX
			let result = "GHDQ-"
			for (let i = 0; i < 6; i++) {
				result += randomChar()
			}
			return result
		}

		// Map the entire current context into a new object to push into the array
		const packageToSave = {
			_id: generateID(),
			reference: generateRef(),
			options: ctxSelectedOptions,
			packages: ctxSelectedPackages,
			total_cost: ctxTotalCost,
			final_cost: ctxFinalCost,
			final_converted_cost: ctxFinalConvertedCost,
			sale_profit: ctxSaleProfit,
			agent_commission: ctxAgentCommission,
			sale_markup: ctxSaleMarkup,
			sale_adjusted_markup: ctxSaleAdjustedMarkup,
			exchange_rate: ctxExchangeRate,
			included_dates: ctxIncludedDates,
			assumed_group_size: ctxGroupSize,
			custom_deposit: parseFloat(ctxDepositValue),
			additional_charges: {
				airport_transfers: {
					included: ctxIncluded.airport_transfers,
					cost: ctxChargeAirportTransfer,
				},
				golf_transfers: {
					included: ctxIncluded.golf_transfers,
					cost: ctxChargeGolfTransfer,
				},
				flights: {
					included: ctxIncluded.flights,
					cost: ctxChargeFlights,
				},
				euro_tunnel: {
					included: ctxIncluded.euro_tunnel,
					cost: ctxChargeEuroTunnel,
				},
				car_hire: {
					included: ctxIncluded.car_hire,
					cost: ctxChargeCarHire,
				},
			},
		}

		// Push this object into the completed packages array
		setCtxCompletedPackages((ctxCompletedPackages) => [...ctxCompletedPackages, packageToSave])

		// Reset the state
		await ctxResetWorkingState()

		// Return to signal a stage change can commence back to the start
		return true
	}

	// Remove a given confirmed package option from the quote context
	const ctxRemoveConfirmedPackage = (removeAtIndex) => {
		// Remove the completed package based on the known index
		setCtxCompletedPackages((completedPackages) => {
			return completedPackages.filter((packageData, index) => index !== removeAtIndex)
		})
	}

	// Clear the entire context state and reset back to defaults
	const ctxClearEntireState = async () => {
		// Empty the completed packages
		setCtxCompletedPackages([])

		// Then reset the remaining state
		await ctxResetWorkingState()
	}

	return (
		<QuoteContext.Provider
			value={{
				ctxCompletedPackages,
				ctxSelectedOptions,
				setCtxSelectedOptions,
				ctxSelectedPackages,
				setCtxSelectedPackages,
				ctxTotalCost,
				ctxFinalCost,
				ctxFinalConvertedCost,
				ctxConvertedBase,
				ctxSellAtCost,
				setCtxSellAtCost,
				ctxSaleMarkup,
				setCtxSaleMarkup,
				ctxSaleAdjustedMarkup,
				ctxSaleProfit,
				ctxAgentCommission,
				ctxIncluded,
				setCtxIncluded,
				ctxGroupSize,
				ctxIncludedDates,
				ctxExchangeRate,
				setCtxExchangeRate,
				ctxChargeAirportTransfer,
				setCtxChargeAirportTransfer,
				ctxChargeGolfTransfer,
				setCtxChargeGolfTransfer,
				ctxChargeFlights,
				setCtxChargeFlights,
				ctxChargeEuroTunnel,
				setCtxChargeEuroTunnel,
				ctxChargeCarHire,
				setCtxChargeCarHire,
				ctxClientID,
				setCtxClientID,
				ctxClientFirstName,
				setCtxClientFirstName,
				ctxClientLastName,
				setCtxClientLastName,
				ctxClientEmail,
				setCtxClientEmail,
				ctxUpdatePackage,
				ctxSaveCompletePackage,
				ctxRemoveConfirmedPackage,
				ctxClearEntireState,
				ctxSavePDFLink,
				ctxDepositValue,
				setCtxDepositValue,
			}}>
			{children}
		</QuoteContext.Provider>
	)
}

export { QuoteContext, QuoteProvider }
