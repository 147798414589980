import { useState, useEffect } from "react"
import { db } from "../../../utils/firebase"
import { NavLink } from "react-router-dom"
import moment from "moment"
import QuoteDocument from "../../../components/client/quotes/components/document"

// Returns the HTML markup for a quote row
export default function QuoteRow({ quote }) {
	// Pull the relevant details from the quote
	const { id, agent, client, created, packages } = quote

	// States
	const [clientName, setClientName] = useState("")
	const [agentName, setAgentName] = useState("")

	// On component load
	useEffect(() => {
		db.doc(`users/${agent}`)
			.get()
			.then((doc) => {
				console.log(doc.data())
				setAgentName(`${doc.data().first_name} ${doc.data().last_name}`)
			})

		db.doc(`clients/${client}`)
			.get()
			.then((clientDoc) => {
				setClientName(`${clientDoc.data().first_name} ${clientDoc.data().last_name}`)
			})
	}, [])

	// Format the removed date
	const createdDate = moment(created?.seconds, "X").format("MMM D[,] YYYY [at] HH:mm")

	return (
		<tr>
			<td>
				<NavLink to={`client/${client}`}>{clientName}</NavLink>
			</td>
			<td>{agentName}</td>
			<td>{createdDate}</td>
			<td className="client-quote-references">
				{packages?.map((quoteObject) => (
					<QuoteDocument
						quoteID={id}
						quotePackage={quoteObject}
					/>
				))}
			</td>
		</tr>
	)
}
