import React, { useState, useEffect } from "react"
import { db, arrayUnion, arrayRemove } from "../../utils/firebase"
import { duplicateHotelIDs, duplicateRoundIDs } from "../../utils/exports/duplicates-with-ids"
import firebase from "firebase"
import moment from "moment"
import * as XLSX from "xlsx"

/**
 * UI components
 */
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Button from "../../components/ui/button/button"
import Input from "../../components/ui/inputs/input"

const Papa = require("papaparse")

export default function Data() {
	const [loading, setLoading] = useState(false)
	const [loadingEnquiries, setLoadingEnquiries] = useState(false)
	const [loadingPayments, setLoadingPayments] = useState(false)
	const [supplierID, setSupplierID] = useState("")
	const [supplierTitle, setSupplierTitle] = useState("")
	const [supplierCheck, setSupplierCheck] = useState([])
	const [supplierCheck02, setSupplierCheck02] = useState([])
	const [supplierDuplictes, setSupplierDuplicates] = useState(0)
	const [canDelete, setCanDelete] = useState(false)
	const [toDelete, setToDelete] = useState([])

	// useEffect(() => {
	//     // Fetch the holidays
	//     db.collection("CMS_courses").get().then((holidayDocs) => {
	//         let holidayIDs = [];
	//         holidayDocs.forEach((holidayDoc) => {
	//             console.log(holidayDoc.data().page_id)
	//             if (typeof holidayDoc.data().page_id === "string") {
	//                 holidayIDs.push(holidayDoc.id);
	//             }
	//         })
	//         setToDelete(holidayIDs)

	//         const nextID = holidayIDs[Math.floor(Math.random() * holidayIDs.length)]
	//         setSupplierID(nextID)
	//     })
	// }, [])

	// useEffect(() => {
	//     if (supplierID) generateIndex()
	// }, [supplierID])

	const readEnquiries = () => {
		const fileToRead = "https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/data%2Fenquiry_info%20(6).csv?alt=media&token=aabe0614-1236-4d09-8228-2a789eb02f25"

		Papa.parse(fileToRead, {
			header: true,
			download: true,
			worker: true,
			step: function (row) {
				setLoading(true)

				const { e_id } = row.data

				if (e_id) {
					console.log(`Processing #${e_id}:`, row.data)

					addEnquiry(row.data)
				}
			},
			complete: function () {
				setLoading(false)

				console.log("Finished reading file")
			},
		})
	}

	const addEnquiry = async (data) => {
		let dateCreatedTS = null
		if (data.e_date_created.length > 0 && data.e_date_created !== "NULL" && data.e_date_created !== "Not Specified") {
			const dateCreatedMillis = moment(data.e_date_created, "YYYY-MM-DD").valueOf()
			dateCreatedTS = firebase.firestore.Timestamp.fromMillis(dateCreatedMillis)
		}

		const clientID = await db
			.collection("clients")
			.where("email", "==", data.c_email)
			.limit(1)
			.get()
			.then(async (clientDoc) => {
				if (clientDoc.empty) {
					return await db
						.collection("clients")
						.add({
							created: dateCreatedTS,
							first_name: data.c_first_name,
							last_name: data.c_last_name,
							phone: data.c_telephone,
							email: data.c_email,
							stableford_points: Number.parseFloat(data.c_stableford),
							old_system: {
								c_id: Number(data.c_id),
							},
						})
						.then(async (newclientDoc) => {
							await db.collection(`clients/${newclientDoc.id}/logs`).add({
								created: dateCreatedTS,
								message: "Client account was created",
								type: "CLIENT_CREATED",
							})

							return newclientDoc.id
						})
				} else {
					return clientDoc.docs[0].id
				}
			})

		const oldAgentID = Number(data.e_staff)

		const agentID = await db
			.collection("users")
			.where("old_system.u_id", "==", oldAgentID)
			.limit(1)
			.get()
			.then((agentDocs) => {
				if (agentDocs.empty) {
					return null
				} else {
					return agentDocs.docs[0].id
				}
			})

		let dateFromTS = null
		if (data.e_date_from.length > 0 && data.e_date_from !== "NULL" && data.e_date_from !== "Not Specified") {
			const dateFromMillis = moment(data.e_date_from, "YYYY-MM-DD").valueOf()
			dateFromTS = firebase.firestore.Timestamp.fromMillis(dateFromMillis)
		}

		let status = "REQUIRE_ATTENTION"
		if (data.e_active === "2") {
			status = "BEING_PROCESSED"
		} else if (data.e_active === "4") {
			status = "HOT"
		} else if (data.e_active === "5") {
			status = "FREEPHONE"
		}

		await db
			.collection("enquiries")
			.add({
				agent: agentID || null,
				client: clientID || null,
				created: dateCreatedTS || null,
				date_from: dateFromTS || null,
				group_size: Number(data.e_group_size) || 0,
				location: data.e_location || "",
				nights: Number(data.e_nights) || 0,
				notes: data.e_notes || "",
				rounds: Number(data.e_rounds) || 0,
				quoted: Number(data.e_per_person) || 0,
				site: data.e_from_site || "NONE",
				status,
			})
			.then((newEnquiry) => {
				console.log(newEnquiry.id)
			})
	}

	const loadPayments = () => {
		const fileToRead = "https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/data%2Frest.csv?alt=media&token=0ddf23bf-36f2-4c1d-bb5a-9604f507accc"

		Papa.parse(fileToRead, {
			header: true,
			download: true,
			worker: true,
			step: function (row) {
				setLoading(true)

				const { e_id } = row.data

				if (e_id) {
					console.log(`Processing payment for enquiry #${e_id}:`, row.data)

					addPayment(row.data)
				}
			},
			complete: function () {
				setLoading(false)

				console.log("Finished reading file")
			},
		})
	}

	const addPayment = async (data) => {
		let dateCreatedTS = null
		if (data.p_date.length > 0 && data.p_date !== "NULL" && data.p_date !== "Not Specified") {
			const dateCreatedMillis = moment(data.p_date, "DD/MM/YYYY").valueOf()
			dateCreatedTS = firebase.firestore.Timestamp.fromMillis(dateCreatedMillis)
		}

		const bookingID = await db
			.collection("bookings")
			.where("old_system.e_id", "==", Number(data.e_id))
			.get()
			.then((bookingDocs) => {
				if (bookingDocs.size > 0) {
					return bookingDocs.docs[0].id
				} else {
					return false
				}
			})

		if (bookingID) {
			console.log(`Found booking ${bookingID} for the payment`)

			const { p_id, e_id, p_payer, p_amount, p_ref } = data

			await db.collection(`bookings/${bookingID}/payments`).add({
				amount: Number.parseFloat(p_amount),
				created: dateCreatedTS,
				paid_date: dateCreatedTS,
				old_system: {
					p_id,
				},
				payee: p_payer,
				reference: p_ref,
				saved_by: null,
			})
		} else {
			console.log(`Couldn't find the booking for ${data.e_id}`)
		}
	}

	// const readData = () => {
	//     const fileToRead =
	//         "https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/data%2Fi_id%20sorted%20to%201000.csv?alt=media&token=8832b75b-6015-41ea-b7a5-20c671672ed8"

	//     Papa.parse(fileToRead, {
	//         header: true,
	//         download: true,
	//         worker: true,
	//         step: function (row) {
	//             setLoading(true)

	//             const { e_id } = row.data

	//             if (e_id) {
	//                 console.log(`Processing enquiry #${e_id}:`, row.data)

	//                 addBooking(row.data)
	//             }
	//         },
	//         complete: function () {
	//             setLoading(false)

	//             console.log("Finished reading file")
	//         },
	//     })
	// }

	// const addBooking = async (data) => {
	//     let dateCreatedTS = null;
	//     if ((data.e_date_created.length > 0) && (data.e_date_created !== "NULL") && (data.e_date_created !== "Not Specified")) {
	//         const dateCreatedMillis = moment(data.e_date_created, "DD/MM/YYYY").valueOf();
	//         dateCreatedTS = firebase.firestore.Timestamp.fromMillis(dateCreatedMillis);
	//     }

	//     const clientID = await db.collection("clients")
	//         .where("email", "==", data.c_email)
	//         .limit(1)
	//         .get().then(async (clientDoc) => {
	//             if (clientDoc.empty) {
	//                 return await db.collection("clients").add({
	//                     created: dateCreatedTS,
	//                     first_name: data.c_first_name,
	//                     last_name: data.c_last_name,
	//                     phone: data.c_telephone,
	//                     email: data.c_email,
	//                     stableford_points: Number.parseFloat(data.c_stableford),
	//                     old_system: {
	//                         c_id: Number(data.c_id),
	//                     }
	//                 }).then(async (newclientDoc) => {
	//                     await db.collection(`clients/${newclientDoc.id}/logs`).add({
	//                         created: dateCreatedTS,
	//                         message: "Client account was created",
	//                         type: "CLIENT_CREATED"
	//                     });

	//                     return newclientDoc.id;
	//                 });
	//             } else {
	//                 return clientDoc.docs[0].id;
	//             }
	//         });

	//     const oldAgentID = Number(data.e_staff);

	//     const agentID = await db.collection("users")
	//         .where("old_system.u_id", "==", oldAgentID)
	//         .limit(1)
	//         .get().then((agentDocs) => {
	//             if (agentDocs.empty) {
	//                 return null;
	//             } else {
	//                 return agentDocs.docs[0].id;
	//             }
	//         });

	//     let balanceDueTS = null;
	//     if ((data.e_balance_due.length > 0) && (data.e_balance_due !== "NULL") && (data.e_balance_due !== "Not Specified")) {
	//         const balanceDueMillis = moment(data.e_balance_due, "DD/MM/YYYY").valueOf();
	//         balanceDueTS = firebase.firestore.Timestamp.fromMillis(balanceDueMillis);
	//     }

	//     let bookedTS = null;
	//     if ((data.i_booked_date.length > 0) && (data.i_booked_date !== "NULL") && (data.i_booked_date !== "Not Specified")) {
	//         const bookedMillis = moment(data.i_booked_date, "DD/MM/YYYY").valueOf();
	//         bookedTS = firebase.firestore.Timestamp.fromMillis(bookedMillis);
	//     }

	//     let dateFromTS = null;
	//     if ((data.e_date_from.length > 0) && (data.e_date_from !== "NULL") && (data.e_date_from !== "Not Specified")) {
	//         const dateFromMillis = moment(data.e_date_from, "DD/MM/YYYY").valueOf();
	//         dateFromTS = firebase.firestore.Timestamp.fromMillis(dateFromMillis);
	//     }

	//     let status = "BEING_PROCESSED";
	//     if (data.e_active === "4") {
	//         status = "HOT";
	//     } else if (data.e_active === "5") {
	//         status = "FREEPHONE";
	//     }

	//     const isConfirmed = data.i_bookingconf === "Yes" ? true : false;

	//     const isRemoved = data.e_active === "0" ? true : false;

	//     const resortsPaid = data.e_resorts_paid === "Yes" ? true : false;

	//     const publicNotes = data.i_comments.replaceAll("Â", "");

	//     const stablefordAssigned = data.i_points_added === "1" ? true : false;

	//     let oldTransferData = { email: "" };
	//     try {
	//         oldTransferData = unserialize(data.i_transfers);
	//     } catch (err) {
	//         console.log(`Enquiry ${data.e_id} error on transfer: ${data.i_transfers}`);
	//     }

	//     const transferDetails = await db.collection("transfers")
	//         .where("email", "==", oldTransferData.email)
	//         .limit(1)
	//         .get().then((transferDoc) => {
	//             if (transferDoc.empty) {
	//                 return { id: null };
	//             } else {
	//                 return { id: transferDoc.docs[0].id, ...transferDoc.docs[0] };
	//             }
	//         });

	//     const group_size = Number(data.e_group_size);
	//     let golfers = Number(data.e_group_size);
	//     if (data.e_golfers > 0) {
	//         golfers = Number(data.e_golfers);
	//     }
	//     const non_golfers = Number(group_size - golfers);

	//     const golferPrice = Number(data.e_per_person);
	//     const nonGolferPrice = Number(data.e_per_ngolfer);

	//     let flightsCost = 0;
	//     if ((data.i_flights !== "NULL") && (Number.parseFloat(data.i_flights) > 0)) {
	//         flightsCost = Number.parseFloat(data.i_flights);
	//     }

	//     let transferCost = 0;
	//     if ((oldTransferData !== "NULL") && (Number.parseFloat(oldTransferData.cost) > 0)) {
	//         transferCost = Number.parseFloat(oldTransferData.cost);
	//     }

	//     const bookingID = await db.collection("bookings").add({
	//         agent: agentID,
	//         balance_due: balanceDueTS,
	//         booked: bookedTS,
	//         client: clientID,
	//         confirmed: isConfirmed,
	//         created: bookedTS,
	//         enquiry: {
	//             created: dateCreatedTS,
	//             date_from: dateFromTS,
	//             group_size: Number(data.e_group_size),
	//             location: data.e_location,
	//             nights: Number(data.e_nights),
	//             notes: data.e_notes,
	//             quoted: Number(data.e_per_person),
	//             rounds: Number(data.e_rounds),
	//             site: data.e_from_site,
	//             status,
	//         },
	//         golf_shuttles: false,
	//         golfers: golfers,
	//         margins: {
	//             exchange_rate: Number.parseFloat(data.i_exchange),
	//             flights_cost: flightsCost,
	//             miscellaneous_costs: 0,
	//             transfers_cost: transferCost,
	//         },
	//         non_golfers: non_golfers,
	//         old_system: {
	//             e_id: Number(data.e_id),
	//             i_id: Number(data.i_id),
	//         },
	//         public_notes: publicNotes,
	//         reference: Number(data.i_booking_ref),
	//         removed: isRemoved,
	//         resorts_paid: resortsPaid,
	//         stableford: {
	//             assigned: stablefordAssigned,
	//         },
	//         transfer: transferDetails.id,
	//         transfer_notes: oldTransferData?.comments || "",
	//         transfer_type: "airport",
	//     }).then((bookingDoc) => { return bookingDoc.id; });

	//     await db.collection(`bookings/${bookingID}/logs`).add({
	//         created: bookedTS,
	//         message: "Booking was created from an existing enquiry",
	//         type: "BOOKING_CREATED",
	//         user: agentID,
	//     });

	//     await db.doc(`clients/${clientID}`).set({
	//         bookings: arrayUnion(bookingID),
	//     }, { merge: true });

	//     await db.collection(`clients/${clientID}/logs`).add({
	//         badge: "INFO",
	//         created: dateCreatedTS,
	//         message: `Submitted a new enquiry for ${data.e_location}`,
	//         type: "ENQUIRY_SUBMITTED"
	//     });

	//     await db.collection(`clients/${clientID}/logs`).add({
	//         badge: "INFO",
	//         created: bookedTS,
	//         message: `Enquiry was converted to a booking for ${data.e_location}`,
	//         type: "BOOKING_CREATED",
	//         user: agentID,
	//     });

	//     await db.collection("enquiries_removed").add({
	//         agent: agentID,
	//         created: dateCreatedTS,
	//         enquired: dateCreatedTS,
	//         enquiry: {
	//             agent: agentID,
	//             client: clientID,
	//             created: dateCreatedTS,
	//             date_from: dateFromTS,
	//             group_size: Number(data.e_group_size),
	//             location: data.e_location,
	//             nights: Number(data.e_nights),
	//             notes: data.e_notes,
	//             quoted: Number(data.e_per_person),
	//             rounds: Number(data.e_rounds),
	//             site: data.e_from_site,
	//             status,
	//         },
	//         site: data.e_from_site,
	//         status,
	//     });

	//     const priceLineGolfers = await db.collection(`bookings/${bookingID}/prices`).add({
	//         created: bookedTS,
	//         name: `Golfer`,
	//     }).then((priceDoc) => { return priceDoc.id; });

	//     await db.doc(`bookings/${bookingID}/prices/${priceLineGolfers}`).set({
	//         quantity: golfers,
	//         price: golferPrice,
	//     }, { merge: true });

	//     await db.doc(`bookings/${bookingID}`).set({
	//         price_lines: {
	//             [priceLineGolfers]: golfers * golferPrice,
	//         },
	//         prices_order: arrayUnion(priceLineGolfers),
	//     }, { merge: true });

	//     if ((non_golfers > 0) && (nonGolferPrice > 0)) {
	//         const priceLineNonGolfers = await db.collection(`bookings/${bookingID}/prices`).add({
	//             created: bookedTS,
	//             name: `Non golfer`,
	//         }).then((priceDoc) => { return priceDoc.id; });

	//         await db.doc(`bookings/${bookingID}/prices/${priceLineGolfers}`).set({
	//             quantity: non_golfers,
	//             price: nonGolferPrice,
	//         }, { merge: true });

	//         await db.doc(`bookings/${bookingID}`).set({
	//             price_lines: {
	//                 [priceLineNonGolfers]: non_golfers * nonGolferPrice,
	//             },
	//             prices_order: arrayUnion(priceLineNonGolfers),
	//         }, { merge: true });
	//     }

	//     const oldAccomodationData = unserialize(data.i_accomodation);

	//     let oldHotelData = { email: "" };
	//     try {
	//         oldHotelData = unserialize(data.i_hotels);
	//     } catch (err) {
	//         console.log(`Enquiry ${data.e_id} error on hotels: ${data.i_hotels}`);
	//     }

	//     const hotelDetails = await db.collection("hotels")
	//         .where("email", "==", oldHotelData.email)
	//         .limit(1)
	//         .get().then((hotelDocs) => {
	//             if (hotelDocs.empty) {
	//                 return { id: null };
	//             } else {
	//                 return { id: hotelDocs.docs[0].id, ...hotelDocs.docs[0] };
	//             }
	//         });

	//     let boardType = "self_catering";
	//     if (oldAccomodationData.type === "Bed & Breakfast") {
	//         boardType = "bed_and_breakfast";
	//     } else if (oldAccomodationData.type === "Dinner, Bed & Breakfast") {
	//         boardType = "dinner_bed_and_breakfast";
	//     } else if (oldAccomodationData.type === "All Inclusive") {
	//         boardType = "all_inclusive";
	//     }

	//     let checkInTS = null;
	//     if ((data.i_check_in !== "NULL") && (data.i_check_in !== "Not Specified")) {
	//         const checkInMillis = moment(data.i_check_in, "DD/MM/YYYY").valueOf();
	//         checkInTS = firebase.firestore.Timestamp.fromMillis(checkInMillis);
	//     }

	//     let rooms = [];
	//     const roomCount = Number(oldAccomodationData.count);

	//     for (let i = 0; i < roomCount; i++) {
	//         rooms.push({ room_type: oldAccomodationData.room.toLowerCase().replaceAll(" ", "_") });
	//     }

	//     const hotelCost = Number.parseFloat(oldHotelData.price) || 0;

	//     const hotelStatus = oldHotelData.confirmed ? "approved" : oldHotelData.sent === "1" ? "sent" : "";

	//     const hotelID = await db.collection(`bookings/${bookingID}/hotels`).add({
	//         board_type: boardType,
	//         check_in: checkInTS,
	//         comments: oldHotelData.comments || "",
	//         created: bookedTS,
	//         nights: Number(data.e_nights),
	//         resortID: hotelDetails.id,
	//         rooms,
	//         total_cost: hotelCost,
	//     }).then((hotelDoc) => { return hotelDoc.id });

	//     await db.doc(`bookings/${bookingID}/hotels/${hotelID}`).set({
	//         booking_status: hotelStatus,
	//         booking_status_comments: oldHotelData.reject_suggestion || "",
	//     }, { merge: true });

	//     await db.doc(`bookings/${bookingID}`).set({
	//         hotels_cost: {
	//             [hotelID]: hotelCost,
	//         },
	//         hotels_order: arrayUnion(hotelID),
	//         margins: {
	//             hotels_cost: hotelCost,
	//         },
	//     }, { merge: true });

	//     const rounds = unserialize(data.i_rounds);

	//     let runningRoundsTotal = 0;

	//     await Promise.all(Object.entries(rounds).map(async (oldRoundData) => {
	//         const courseDetails = await db.collection("courses")
	//             .where("email", "==", oldRoundData[1].email)
	//             .limit(1)
	//             .get().then((courseDocs) => {
	//                 if (courseDocs.empty) {
	//                     return { id: null };
	//                 } else {
	//                     return { id: courseDocs.docs[0].id, ...courseDocs.docs[0] };
	//                 }
	//             });

	//         let teeTimeTS = null;
	//         if (oldRoundData[1].info && (oldRoundData[1].info !== "NULL") && (oldRoundData[1].info !== "Not Specified")) {
	//             const teeTimeMillis = moment(oldRoundData[1].info, "DD/MM/YYYY HH:mm").valueOf();
	//             teeTimeTS = firebase.firestore.Timestamp.fromMillis(teeTimeMillis);
	//         }

	//         const roundStatus = oldRoundData[1].confirmed ? "approved" : oldRoundData[1].sent === "1" ? "sent" : "";

	//         const hasBuggies = data.i_buggies === "Yes" ? true : false;

	//         const roundID = await db.collection(`bookings/${bookingID}/rounds`).add({
	//             buggies: hasBuggies,
	//             comments: "",
	//             courseID: courseDetails.id,
	//             cost_per_golfer: Number(oldRoundData[1].price),
	//             created: bookedTS,
	//             tee_time: teeTimeTS,
	//         }).then((roundDoc) => { return roundDoc.id });

	//         runningRoundsTotal = runningRoundsTotal + Number(oldRoundData[1].price);

	//         await db.doc(`bookings/${bookingID}/rounds/${roundID}`).set({
	//             booking_status: roundStatus,
	//             booking_status_comments: "",
	//         }, { merge: true });

	//         await db.doc(`bookings/${bookingID}`).set({
	//             round_costs: {
	//                 [roundID]: Number(oldRoundData[1].price),
	//             },
	//             rounds_order: arrayUnion(roundID),
	//         }, { merge: true });
	//     }));

	//     const costForGolfers = Number(golfers * golferPrice);
	//     const costForNonGolfers = Number(non_golfers * nonGolferPrice);

	//     let totalCustomerPrice = costForGolfers;
	//     if ((non_golfers > 0) && (nonGolferPrice > 0)) {
	//         totalCustomerPrice = totalCustomerPrice + costForNonGolfers;
	//     }

	//     const stablefordPoints = Number.parseFloat((totalCustomerPrice / 10).toFixed(2));

	//     const supplier_invoices = Number.parseFloat(((flightsCost || 0) + (transferCost || 0) + (hotelCost || 0) + (runningRoundsTotal || 0)).toFixed(2));

	//     const bookingMargin = (totalCustomerPrice - supplier_invoices);
	//     const agentMargin = Number.parseFloat((bookingMargin / 10).toFixed(2));

	//     await db.doc(`bookings/${bookingID}`).set({
	//         balance_outstanding: totalCustomerPrice,
	//         margins: {
	//             agents_margin: agentMargin,
	//             customer_price: totalCustomerPrice,
	//             profit: bookingMargin,
	//             rounds_cost: runningRoundsTotal,
	//             supplier_invoices,
	//         },
	//         stableford: {
	//             amount: stablefordPoints,
	//         }
	//     }, { merge: true });
	// }

	const findBookings = async () => {
		// const duplicateHotelPartners = await db.collection("courses")
		//     .get().then((hotelDocs) => {
		//         console.log(hotelDocs.size)
		//         let hotelIDs = [];
		//         hotelDocs.forEach((hotelDoc) => {
		//             if (roundDuplicates.includes(hotelDoc.data().email)) {
		//                 hotelIDs.push(hotelDoc.id);
		//                 console.log(`Partner ${hotelDoc.id} has a duplicate email ${hotelDoc.data().email}`);
		//             }
		//         });
		//         return hotelIDs;
		//     });

		// console.log(duplicateHotelPartners);

		const chunckedHotels = duplicateHotelIDs.reduce((resultArray, item, index) => {
			const chunkIndex = Math.floor(index / 10)

			if (!resultArray[chunkIndex]) {
				resultArray[chunkIndex] = []
			}

			resultArray[chunkIndex].push(item)

			return resultArray
		}, [])

		// for await (const snap of chunckedHotels.map(async (chunk) => {
		//     db.collection("bookings")
		//         .where("resortID", "in", chunk)
		//         .get().then((docs) => {
		//             console.log(docs);
		//         });
		// }));

		let bookings = []
		const startOfDec = moment("01-12-2021", "DD-MM-YYYY").startOf("day").format("X")

		for await (const id of duplicateRoundIDs.map(async (chunk) => {
			await db
				.collection("bookings")
				.where("suppliers_rounds", "array-contains", chunk)
				.get()
				.then((docs) => {
					console.log(`Bookings containing ${chunk}: ${docs.size}`)

					docs.forEach((doc) => {
						if (doc.data().check_in?.seconds > startOfDec) {
							const checkInDate = moment(doc.data().check_in?.seconds, "X").format("DD-MM-YYYY")
							const bookedDate = moment(doc.data().booked?.seconds, "X").format("DD-MM-YYYY")

							bookings.push({
								id: doc.id,
								reference: doc.data().reference,
								booked: bookedDate,
								check_in: checkInDate,
								new_system_link: `https://ghd-booking-system.web.app/booking/${doc.id}`,
								old_system_link: doc.data().old_system?.e_id ? `https://ghdsystem.com/itinerary.php?itin=${doc.data().old_system?.e_id}` : "",
							})
						}
					})
				})
		}));

		console.log(bookings)
	}

	function delay(time) {
		return new Promise((resolve) => setTimeout(resolve, time))
	}

	const pushUpdateTo = async () => {
		const bookingDocuments = await db.collection("bookings").get()

		let collectionSize = bookingDocuments.size

		for await (const bookingDoc of bookingDocuments.docs) {
			console.log(`Started processing ${bookingDoc.id}`)

			const { golfers, margins } = bookingDoc.data()

			if (margins?.exchange_rate) {
				let hotelPrices = {}
				let roundPrices = {}

				let totalHotelCosts = 0
				let totalRoundCosts = 0

				await db
					.collection(`bookings/${bookingDoc.id}/hotels`)
					.get()
					.then(async (hotelDocs) => {
						for await (const hotelDoc of hotelDocs.docs) {
							hotelPrices[hotelDoc.id] = Number.parseFloat(hotelDoc.data().total_cost) || 0

							totalHotelCosts = totalHotelCosts + Number.parseFloat(hotelDoc.data().total_cost)

							console.log("Processed hotel")
						}
					})

				await db
					.collection(`bookings/${bookingDoc.id}/rounds`)
					.get()
					.then(async (roundDocs) => {
						for await (const roundDoc of roundDocs.docs) {
							roundPrices[roundDoc.id] = Number.parseFloat(roundDoc.data().cost_per_golfer) || 0

							totalRoundCosts = totalRoundCosts + golfers * Number.parseFloat(roundDoc.data().cost_per_golfer)

							console.log("Processed round")
						}
					})

				let totalPaidByClient = 0

				await db
					.collection(`bookings/${bookingDoc.id}/payments`)
					.get()
					.then(async (paymentDocs) => {
						for await (const paymentDoc of paymentDocs.docs) {
							if (paymentDoc.data().amount) {
								totalPaidByClient = totalPaidByClient + Number.parseFloat(paymentDoc.data().amount)

								console.log("Processed booking payment")
							}
						}
					})

				console.log("Hotel prices:", hotelPrices)
				console.log("Hotel total cost:", Number.parseFloat(totalHotelCosts))

				console.log("Round prices:", roundPrices)
				console.log("Round total cost:", Number.parseFloat(totalRoundCosts))

				console.log("Total paid by client:", Number.parseFloat(totalPaidByClient))
				console.log("Balance outstanding:", Number.parseFloat(margins.customer_price - totalPaidByClient))

				const balanceOutstanding = Number.parseFloat(margins.customer_price - totalPaidByClient)

				console.log("Transfers cost:", margins.transfers_cost)
				console.log("Flights cost:", margins.flights_cost)
				console.log("Miscellaneous cost:", margins.miscellaneous_costs)

				const hotelsFixed = Number.parseFloat((totalHotelCosts / margins.exchange_rate).toFixed(2))
				const roundsFixed = Number.parseFloat((totalRoundCosts / margins.exchange_rate).toFixed(2))
				const transfersFixed = Number.parseFloat((margins.transfers_cost / margins.exchange_rate).toFixed(2))

				const cost_hotels = Number.parseFloat(hotelsFixed)
				const cost_rounds = Number.parseFloat(roundsFixed)
				const cost_transfers = Number.parseFloat(transfersFixed)
				const cost_flights = Number.parseFloat(margins.flights_cost)
				const cost_miscellaneous = Number.parseFloat(margins.miscellaneous_costs)

				const supplier_invoices = Number.parseFloat((cost_hotels + cost_rounds + cost_transfers + cost_flights + cost_miscellaneous).toFixed(2))

				console.log("Total supplier invoices:", supplier_invoices)

				const customer_price = Number.parseFloat(margins.customer_price)

				const total_profit = Number.parseFloat((customer_price - supplier_invoices).toFixed(2))
				const total_margin = Number.parseFloat((total_profit / 10).toFixed(2))

				console.log("Customer price:", customer_price)
				console.log("Profit:", total_profit)
				console.log("Margin:", total_margin)

				await db.doc(`bookings/${bookingDoc.id}`).set(
					{
						hotels_cost: hotelPrices,
						margins: {
							agents_margin: total_margin,
							customer_price: customer_price,
							profit: total_profit,
							supplier_invoices: supplier_invoices,
							hotels_cost: Number.parseFloat(totalHotelCosts) || 0,
							rounds_cost: Number.parseFloat(totalRoundCosts) || 0,
						},
						round_costs: roundPrices,
					},
					{ merge: true }
				)

				collectionSize--

				console.log(`Processed ${bookingDoc.id}`)
				console.log(`${collectionSize} left to process`)

				console.log("")

				await delay(50)
			}
		}
	}

	const pullAllBookings = async () => {
		let bookings = []
		const startOfDec = moment("01-12-2021", "DD-MM-YYYY").startOf("day").format("X")

		await db
			.collection("bookings")
			.orderBy("check_in")
			.get()
			.then((docs) => {
				docs.forEach((doc) => {
					if (doc.data().check_in?.seconds > startOfDec) {
						const checkInDate = moment(doc.data().check_in?.seconds, "X").format("DD-MM-YYYY")
						const bookedDate = moment(doc.data().booked?.seconds, "X").format("DD-MM-YYYY")

						bookings.push({
							id: doc.id,
							reference: doc.data().reference,
							booked: bookedDate,
							check_in: checkInDate,
							new_system_link: `https://ghd-booking-system.web.app/booking/${doc.id}`,
						})
					}
				})
			})

		console.log(bookings)
	}

	const bookingsSize = async () => {
		db.collection("bookings")
			.where("reference", "==", 6412840)
			.get()
			.then((bookingDocs) => {
				bookingDocs.forEach((bookingDoc) => {
					console.log(bookingDoc.id)
				})
				console.log(bookingDocs.size)
			})
	}

	const missingHotels = async () => {
		db.collection("bookings")
			.get()
			.then((bookingDocs) => {
				bookingDocs.forEach((bookingDoc) => {
					if (!bookingDoc.data().check_in) {
						console.log(bookingDoc.id)
					}
				})
			})
	}

	const pushEnquiryUpdates = async () => {
		const enquiryDocuments = await db.collection("enquiries").get()

		let collectionSize = enquiryDocuments.size

		for await (const enquiryDoc of enquiryDocuments.docs) {
			console.log(`Started processing ${enquiryDoc.id}`)

			await db.doc(`enquiries/${enquiryDoc.id}`).set(
				{
					updated: firebase.firestore.FieldValue.serverTimestamp(),
				},
				{ merge: true }
			)

			collectionSize--

			console.log(`${collectionSize} left`)

			await delay(50)
		}
	}

	const pullNoPrice = async () => {
		db.collection("enquiries")
			.where("created", ">=", firebase.firestore.Timestamp.fromMillis(moment("17-01-2022 17:00", "DD-MM-YYYY HH:mm").valueOf()))
			.get()
			.then((bookingDocs) => {
				console.log(bookingDocs.size)
				bookingDocs.forEach((booking) => {
					console.log(booking.id, booking.data().status, booking.data().agent)
				})
			})
	}

	const findNoFollowUp = async () => {
		let i = 0

		let toRemove = []

		await db
			.collection("enquiries")
			.get()
			.then((bookingDocs) => {
				bookingDocs.forEach((booking) => {
					const { follow_up, status } = booking.data()

					if (!follow_up && status !== "REQUIRE_ATTENTION") {
						console.log(booking.id, status, follow_up)
						toRemove.push(booking.id)
						i++
					}
				})

				console.log("Total size:", bookingDocs.size)
				console.log("To delete:", i)
				console.log("Left:", bookingDocs.size - i)
				console.log("Removal array:", toRemove.length)
			})

		let leftToGo = toRemove.length

		for await (const booking of toRemove) {
			console.log("Removing ", booking)

			db.doc(`enquiries/${booking}`).delete()

			leftToGo--

			console.log(`Done, got ${leftToGo} left`)

			await delay(150)
		}
	}

	const checkForDepositReminder = async () => {
		/**
		 * Generate a timestamp 14 days in the past, from the start of today
		 */
		const fourteenDaysAgoStart = moment().startOf("day").subtract(14, "days").valueOf()
		const fourteenDaysAgoEnd = moment().endOf("day").subtract(14, "days").valueOf()
		/**
		 * Generate those dates as timestamps to query the database with
		 */
		const fourteenDaysAgoStartTimestamp = firebase.firestore.Timestamp.fromMillis(fourteenDaysAgoStart)
		const fourteenDaysAgoEndTimestamp = firebase.firestore.Timestamp.fromMillis(fourteenDaysAgoEnd)
		/**
		 * Pull a list of enquries from the database that match the requirements
		 */
		return db
			.collection("bookings")
			.where("booked", ">=", fourteenDaysAgoStartTimestamp)
			.where("booked", "<=", fourteenDaysAgoEndTimestamp)
			.where("paid_by_client", "==", 0)
			.get()
			.then((bookingDocs) => {
				/**
				 * Write to the console the amount of enquiries found
				 */
				console.log(`Found ${bookingDocs.size} bookings that need deposit emails`)
				/**
				 * Loop over each of the enquries found
				 */
				bookingDocs.forEach((bookingDoc) => {
					console.log(bookingDoc.id)
				})
			})
	}

	const checkForPaidByField = async () => {
		let without = []

		await db
			.collection("bookings")
			.get()
			.then((bookingDocs) => {
				bookingDocs.forEach(async (bookingDoc) => {
					const { paid_by_client } = bookingDoc.data()
					if (paid_by_client || paid_by_client === 0) {
						console.log(`Booking [${bookingDoc.id}] has paid by field, skipping...`)
					} else {
						without.push(bookingDoc.id)
						console.log(`Booking [${bookingDoc.id}] does not have the field, adding it now...`)

						await db.doc(`bookings/${bookingDoc.id}`).set(
							{
								paid_by_client: 0,
							},
							{ merge: true }
						)
					}
				})
			})

		console.log(`Found without the field: ${without.length}`)
	}

	const checkForOldSupplier = async () => {
		const timer = (ms) => new Promise((res) => setTimeout(res, ms))

		const bookingDocs = await db.collection("bookings").get()

		let cursor = 1

		for (const bookingDoc of bookingDocs.docs) {
			console.log(`Starting on booking ${bookingDoc.id} leaving ${bookingDocs.size - cursor} to do`)
			cursor++

			const { suppliers_hotels, suppliers_rounds } = bookingDoc.data()

			await db
				.collection(`bookings/${bookingDoc.id}/hotels`)
				.get()
				.then(async (hotelDocs) => {
					if (suppliers_hotels && suppliers_hotels.length > hotelDocs.size) {
						let verifiedCorrectIDs = []

						for await (const hotelDoc of hotelDocs.docs) {
							verifiedCorrectIDs.push(hotelDoc.data().resortID)
						}

						let oldIDs = []

						suppliers_hotels.forEach((supplierID) => {
							if (!verifiedCorrectIDs.includes(supplierID)) {
								oldIDs.push(supplierID)
							}
						})

						console.log("Might have old hotel suppliers:", bookingDoc.id, `(${suppliers_hotels.length} but only ${hotelDocs.size} records)`)

						console.log("The following IDs were not found in hotel records:", oldIDs)

						console.log("Removing them from the booking...")

						await db.doc(`bookings/${bookingDoc.id}`).update({
							suppliers_hotels: arrayRemove(...oldIDs),
						})
					}
				})

			await db
				.collection(`bookings/${bookingDoc.id}/rounds`)
				.get()
				.then(async (roundDocs) => {
					if (suppliers_rounds && suppliers_rounds.length > roundDocs.size) {
						let verifiedCorrectIDs = []

						for await (const roundDoc of roundDocs.docs) {
							verifiedCorrectIDs.push(roundDoc.data().courseID)
						}

						let oldIDs = []

						suppliers_rounds.forEach((supplierID) => {
							if (!verifiedCorrectIDs.includes(supplierID)) {
								oldIDs.push(supplierID)
							}
						})

						console.log("Might have old round suppliers:", bookingDoc.id, `(${suppliers_rounds.length} but only ${roundDocs.size} records)`)

						console.log("The following IDs were not found in round records:", oldIDs)

						console.log("Removing them from the booking...")

						await db.doc(`bookings/${bookingDoc.id}`).update({
							suppliers_rounds: arrayRemove(...oldIDs),
						})
					}
				})

			await timer(1000)
		}
	}

	const findNoFollowUpForAgent = async () => {
		let i = 0

		let toRemove = []

		await db
			.collection("enquiries")
			.get()
			.then((enquiryDocs) => {
				enquiryDocs.forEach((enquiry) => {
					const { agent, follow_up, status } = enquiry.data()

					if (!follow_up && status === "BEING_PROCESSED" && agent === "4WIQpERmnaYHWxnibtsKYjHtMBo2") {
						console.log(enquiry.id, status, follow_up, agent)
						toRemove.push(enquiry.id)
						i++
					}
				})

				console.log("Total size:", enquiryDocs.size)
				console.log("To delete:", i)
				console.log("Left:", enquiryDocs.size - i)
				console.log("Removal array:", toRemove.length)
			})

		let leftToGo = toRemove.length

		for await (const enquiry of toRemove) {
			console.log("Removing ", enquiry)

			db.doc(`enquiries/${enquiry}`).delete()

			leftToGo--

			console.log(`Done, got ${leftToGo} left`)

			await delay(150)
		}
	}

	const fetchPartnershipBookings = async () => {
		const bookings = []

		const startOf = moment("04-05-2022", "DD-MM-YYYY").startOf("day").format("X")

		let totalBookings = 0
		let totalSales = 0
		let totalProfit = 0

		await db
			.collection("bookings")
			.where("enquiry.site", "in", ["MGH", "MGB"])
			.get()
			.then((docs) => {
				docs.forEach((doc) => {
					if (doc.data().enquiry.created.seconds > startOf) {
						bookings.push({
							id: doc.id,
							customer_price: parseFloat(doc.data().margins?.customer_price.toFixed(2)),
							supplier_invoices: parseFloat(doc.data().margins?.supplier_invoices.toFixed(2)),
							agents_margin: parseFloat(doc.data().margins?.agents_margin.toFixed(2)),
							profit: parseFloat((doc.data().margins?.profit - doc.data().margins?.agents_margin).toFixed(2)),
						})

						totalBookings = totalBookings + 1
						totalSales = totalSales + doc.data().margins?.customer_price
						totalProfit = totalProfit + doc.data().margins?.profit
					}
				})
			})

		console.log(bookings)
		console.log(`Total bookings:`, totalBookings)
		console.log(`Total sales:`, totalSales)
		console.log(`Total profit:`, parseInt(totalProfit))
	}

	const findMissingBooking = async () => {
		await db
			.collection("bookings")
			.where("reference", "==", 2441328)
			.get()
			.then((booking) => {
				console.log(booking.docs[0])
			})
	}

	const fetchByYear = async () => {
		let agents = []

		const fetchStart = moment(`2022`, "YYYY").startOf("year").valueOf()
		const fetchEnd = moment(`2022`, "YYYY").endOf("year").valueOf()

		const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
		const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)

		await db
			.collection("bookings")
			.where(`check_in`, ">=", startTimestamp)
			.where(`check_in`, "<=", endTimestamp)
			.where("removed", "==", false)
			.orderBy(`check_in`)
			.get()
			.then((bookingDocs) => {
				bookingDocs.forEach((booking) => {
					const { agent, margins } = booking.data()

					if (agent) {
						const { customer_price, flights_cost, supplier_invoices, agents_margin } = margins || {}

						const agentExists = agents.find((agentBookings) => agentBookings.id === agent)

						if (agentExists) {
							const agentIndex = agents.findIndex((agentBookings) => agentBookings.id === agent)

							agents[agentIndex].margin += agents_margin
						} else {
							agents.push({ id: agent, margin: agents_margin })
						}
					}
				})
			})

		agents.sort((a, b) => a.margin - b.margin)

		console.log(agents)
	}

	const generateIndex = async () => {
		setSupplierCheck([])
		setCanDelete(false)
		const newArr = []

		let holidaysWithStringID = 0
		let coursesWithStringID = 0

		await db
			.collection("CMS_holidays")
			.get()
			.then((holidayDocs) => {
				holidayDocs.forEach((holidayDoc) => {
					if (typeof holidayDoc.data().page_id === "string") {
						holidaysWithStringID++
					}
				})
			})

		await db
			.collection("CMS_courses")
			.get()
			.then((courseDocs) => {
				courseDocs.forEach((courseDoc) => {
					if (typeof courseDoc.data().page_id === "string") {
						coursesWithStringID++
					}
				})
			})

		setSupplierDuplicates(holidaysWithStringID + coursesWithStringID)

		const isHotelSupplier = await db
			.doc(`CMS_holidays/${supplierID}`)
			.get()
			.then((supplierDoc) => {
				newArr.push(["Supplier exists in CMS_holidays:", supplierDoc.exists ? "Yes" : "No"])
				return supplierDoc.exists ? supplierDoc.data() : null
			})

		const isCourseSupplier = await db
			.doc(`CMS_courses/${supplierID}`)
			.get()
			.then((supplierDoc) => {
				newArr.push(["Supplier exists in CMS_courses:", supplierDoc.exists ? "Yes" : "No"])
				return supplierDoc.exists ? supplierDoc.data() : null
			})

		let IDTypes = null
		let priceWindowsCount = 0

		if (isHotelSupplier) {
			await db
				.collection(`CMS_holidays/${supplierID}/price_windows`)
				.get()
				.then((priceWindows) => {
					newArr.push(["Price windows:", priceWindows.size])
					priceWindowsCount = priceWindows.size
				})

			newArr.push(["Page ID type:", typeof isHotelSupplier?.page_id])
			newArr.push(["Parent ID type:", typeof isHotelSupplier?.parent_id])
			IDTypes = typeof isHotelSupplier?.page_id

			setSupplierTitle(`${isHotelSupplier?.title} (${supplierID})`)
		} else {
			await db
				.collection(`CMS_holidays/${supplierID}/price_windows`)
				.get()
				.then((priceWindows) => {
					newArr.push(["Price windows:", priceWindows.size])
					priceWindowsCount = priceWindows.size
				})

			newArr.push(["Page ID type:", typeof isCourseSupplier?.page_id])
			newArr.push(["Parent ID type:", typeof isCourseSupplier?.parent_id])
			IDTypes = typeof isCourseSupplier?.page_id

			setSupplierTitle(`${isCourseSupplier?.title} (${supplierID})`)
		}

		const hasBookingsHotel = await db
			.collection("bookings")
			.where("suppliers_hotels", "array-contains", supplierID)
			.get()
			.then((hotelSuppliers) => {
				newArr.push(["Hotel supplier to:", hotelSuppliers.size])
				return hotelSuppliers.size > 0
			})

		const hasBookingsCourse = await db
			.collection("bookings")
			.where("suppliers_courses", "array-contains", supplierID)
			.get()
			.then((courseSuppliers) => {
				newArr.push(["Course supplier to:", courseSuppliers.size])
				return courseSuppliers.size > 0
			})
		console.log(priceWindowsCount)

		const canBeDeleted = !hasBookingsHotel && !hasBookingsCourse && IDTypes === "string" && priceWindowsCount === 0

		newArr.push(["Considered to be the duplicate?", IDTypes === "string" ? "Yes" : "No"])
		newArr.push(["Can it be removed?", canBeDeleted ? "Yes" : "No"])
		setSupplierCheck(newArr)
		setCanDelete(canBeDeleted)

		if (canBeDeleted) {
			// Kick off a multiple second timer
			setTimeout(() => {
				deleteSupplier()
			}, 2500)
		}
	}

	const deleteSupplier = async () => {
		// Delete the supplier
		await db.doc(`CMS_courses/${supplierID}`).delete()

		// Create a new array without the ID
		const updatedToDelete = toDelete.filter((supplier) => supplier !== supplierID)

		// Update the state
		setToDelete(updatedToDelete)

		// Reset the state
		setSupplierCheck([])
		setCanDelete(false)
		setSupplierTitle("")
		setSupplierID("")
		setSupplierDuplicates(0)

		// Wait for a second
		setTimeout(() => {
			// Fetch a random ID from the toDelete array
			const nextID = toDelete[Math.floor(Math.random() * toDelete.length)]

			// Set the ID in the state
			setSupplierID(nextID)
		}, 100)
	}

	const fetchBookingsToChangeMargin = async () => {
		db.collection("bookings")
			.where("check_in", ">=", firebase.firestore.Timestamp.fromMillis(moment("01-03-2024", "DD-MM-YYYY").valueOf()))
			.get()
			.then(async (bookingDocs) => {
				let collectionSize = bookingDocs.size
				console.log("Total bookings:", collectionSize)

				for await (const bookingDoc of bookingDocs.docs) {
					console.log(`Started processing ${bookingDoc.id}`)

					const { margins } = bookingDoc.data()
					console.log("Agent margin:", margins?.agents_margin)

					if (!isNaN(margins?.agents_margin)) {
						const newMargin = Number.parseFloat((margins?.agents_margin / 2).toFixed(2))
						console.log("Agent adjusted margin:", newMargin)

						await db.doc(`bookings/${bookingDoc.id}`).set(
							{
								margins: {
									agents_margin: newMargin,
									agents_margin_10: margins?.agents_margin,
								},
							},
							{ merge: true }
						)
					}

					collectionSize--

					console.log(`Processed ${bookingDoc.id}`)
					console.log(`${collectionSize} left to process`)

					console.log("")

					await delay(50)
				}
			})
	}

	const BS3_updateUsers = async () => {
		db.collection("users")
			.limit(100)
			.get()
			.then(async (clientDocs) => {
				let collectionSize = clientDocs.size
				console.log("Total users:", collectionSize)

				for await (const clientDoc of clientDocs.docs) {
					console.log(`Started processing ${clientDoc.id}`)

					await db.doc(`users/${clientDoc.id}`).set(
						{
							sync_service: {
								bs3_update: firebase.firestore.FieldValue.serverTimestamp(),
							},
						},
						{ merge: true }
					)

					collectionSize--

					console.log(`Processed ${clientDoc.id}`)
					console.log(`${collectionSize} left to process`)

					console.log("")

					await delay(50)
				}
			})
	}

	const BS3_updateClients = async () => {
		db.collection("clients")
			.get()
			.then(async (clientDocs) => {
				let collectionSize = clientDocs.size
				console.log("Total clients:", collectionSize)

				for await (const clientDoc of clientDocs.docs) {
					console.log(`Started processing ${clientDoc.id}`)

					await db.doc(`clients/${clientDoc.id}`).set(
						{
							sync_service: {
								bs3_update: firebase.firestore.FieldValue.serverTimestamp(),
							},
						},
						{ merge: true }
					)

					collectionSize--

					console.log(`Processed ${clientDoc.id}`)
					console.log(`${collectionSize} left to process`)

					console.log("")

					await delay(10)
				}
			})
	}

	const findUsersExpiredEnquiries = async () => {
		let i = 0

		let toRemove = []

		await db
			.collection("enquiries")
			.where("agent", "==", "4BCINn5MpjUnma2MmdmnutWvD1r1")
			.where("follow_up", "<", firebase.firestore.Timestamp.fromMillis(moment("2024-07-15", "YYYY-MM-DD").startOf("day").valueOf()))
			.get()
			.then((enquiryDocs) => {
				enquiryDocs.forEach((enquiry) => {
					const { agent, follow_up, status } = enquiry.data()

					console.log(enquiry.id, status, follow_up, agent)
					toRemove.push(enquiry.id)
					i++
				})

				console.log("Total size:", enquiryDocs.size)
				console.log("To delete:", i)
				console.log("Left:", enquiryDocs.size - i)
				console.log("Removal array:", toRemove.length)
			})

		// let leftToGo = toRemove.length

		// for await (const enquiry of toRemove) {
		// 	console.log("Removing ", enquiry)

		// 	db.doc(`enquiries/${enquiry}`).delete()

		// 	leftToGo--

		// 	console.log(`Done, got ${leftToGo} left`)

		// 	await delay(150)
		// }
	}

	// Calculate percentage increase (used in the below for supplier data)
	const calculatePercentageIncrease = (previous, current) => {
		if (previous === 0) return current === 0 ? 0 : 100
		return ((current - previous) / previous) * 100
	}

	const fetchAllHotelData = async () => {
		const suppliers = await db.collection("hotels").orderBy("name", "asc").limit(50).get()
		let allData = []
		let increm = 1

		// Iterate over each supplier
		for (let supplierDoc of suppliers.docs) {
			const supplierID = supplierDoc.id
			const supplierName = supplierDoc.data().name
			console.log("Aggregating " + supplierName)
			console.log(increm + "/" + suppliers.docs.length)
			increm++

			// Create an object to store supplier's aggregated data
			let supplierData = {
				supplier: supplierName,
				bookings_2022: 0,
				bookings_2023: 0,
				bookings_2024: 0,
				bookings_2025: 0,
				golfers_2022: 0,
				golfers_2023: 0,
				golfers_2024: 0,
				golfers_2025: 0,
			}

			// Define date ranges for each year
			const dateRanges = {
				2022: [new Date("2022-01-01"), new Date("2022-12-31")],
				2023: [new Date("2023-01-01"), new Date("2023-12-31")],
				2024: [new Date("2024-01-01"), new Date("2024-12-31")],
				2025: [new Date("2025-01-01"), new Date("2025-12-31")],
			}

			// Fetch and tally booking data for each year
			for (let year in dateRanges) {
				const [startTimestamp, endTimestamp] = dateRanges[year]

				const bookingDocs = await db
					.collection("bookings")
					.where("check_in", ">=", startTimestamp)
					.where("check_in", "<=", endTimestamp)
					.where("suppliers_hotels", "array-contains", supplierID)
					.where("removed", "==", false)
					.get()

				let bookingsArr = []
				let totalGolfers = 0

				bookingDocs.forEach((bookingDoc) => {
					const bookingData = bookingDoc.data()
					bookingsArr.push(bookingData)
					totalGolfers += bookingData.golfers || 0
				})

				supplierData[`bookings_${year}`] = bookingsArr.length
				supplierData[`golfers_${year}`] = totalGolfers
			}

			// Calculate percentage increases
			supplierData["bookings_2022_2023_increase"] = calculatePercentageIncrease(supplierData.bookings_2022, supplierData.bookings_2023) || 0
			supplierData["bookings_2023_2024_increase"] = calculatePercentageIncrease(supplierData.bookings_2023, supplierData.bookings_2024) || 0
			supplierData["bookings_2024_2025_increase"] = calculatePercentageIncrease(supplierData.bookings_2024, supplierData.bookings_2025) || 0

			supplierData["golfers_2022_2023_increase"] = calculatePercentageIncrease(supplierData.golfers_2022, supplierData.golfers_2023) || 0
			supplierData["golfers_2023_2024_increase"] = calculatePercentageIncrease(supplierData.golfers_2023, supplierData.golfers_2024) || 0
			supplierData["golfers_2024_2025_increase"] = calculatePercentageIncrease(supplierData.golfers_2024, supplierData.golfers_2025) || 0

			allData.push(supplierData)
		}

		return allData
	}

	const supplierDataToExcel = (bookingData) => {
		if (!bookingData || bookingData.length === 0) {
			console.error("Empty data array")
			return
		}

		const columns = Object.keys(bookingData[0])

		const formattedData = bookingData.map((item) => {
			const rowData = {}
			columns.forEach((column) => {
				rowData[column] = item[column] !== undefined ? item[column] : ""
			})
			return rowData
		})

		const worksheet = XLSX.utils.json_to_sheet(formattedData)

		const workbook = XLSX.utils.book_new()
		XLSX.utils.book_append_sheet(workbook, worksheet, "2025")

		XLSX.writeFile(workbook, `BookingSupplierData_Travellers23.xlsx`)
	}

	const fetchCMBookings = async () => {
		const appData = []

		// Define date ranges for each year
		const dateRanges = {
			// 2022: [new Date("2022-01-01"), new Date("2022-12-31")],
			2023: [new Date("2023-01-01"), new Date("2023-12-31")],
			// 2024: [new Date("2024-01-01"), new Date("2024-12-31")],
			// 2025: [new Date("2025-01-01"), new Date("2025-12-31")],
		}

		// Fetch and tally booking data for each year
		for (let year in dateRanges) {
			const [startTimestamp, endTimestamp] = dateRanges[year]

			await db
				.collection("bookings")
				.where("check_in", ">=", startTimestamp)
				.where("check_in", "<=", endTimestamp)
				.where("removed", "==", false)
				.orderBy("check_in")
				.get()
				.then(async (bookingDocs) => {
					let increm = 1

					for (let booking of bookingDocs.docs) {
						const bookingData = booking.data()
						const bookingMargins = bookingData.margins
						console.log("Processing " + increm + "/" + bookingDocs.docs.length)
						increm++

						const bookingInfo = {
							BookingID: booking.id,
							Reference: bookingData.reference,
							Channel: bookingData?.enquiry?.site || "SYSTEM",
							Travelling: moment(bookingData.check_in?.seconds, "X").format("DD-MM-YYYY"),
							PAX: bookingData.golfers + bookingData.non_golfers,
							Supplier: "",
							Type: "hotel",
							IGTM: "",
							Person: "",
							Country: "",
							FlightsInvoice: isNaN(bookingMargins.flights_cost) ? 0 : bookingMargins.flights_cost,
							HotelsInvoice: isNaN(bookingMargins.hotels_cost) ? 0 : bookingMargins.hotels_cost,
							RoundsInvoice: 0,
							TransfersInvoice: isNaN(bookingMargins.transfers_cost) ? 0 : bookingMargins.transfers_cost,
							MiscellaneousInvoice: isNaN(bookingMargins.miscellaneous_costs) ? 0 : bookingMargins.miscellaneous_costs,
							TotalInvoice: isNaN(bookingMargins.supplier_invoices) ? 0 : bookingMargins.supplier_invoices,
							Confirmed: bookingData.confirmed ? "TRUE" : "FALSE",
							Removed: bookingData.removed ? "TRUE" : "FALSE",
						}

						const hotelSuppliers = booking.data().suppliers_hotels
						const roundSuppliers = booking.data().suppliers_rounds
						const transferSupplier = booking.data().transfer

						if (hotelSuppliers && hotelSuppliers?.length > 0) {
							for (let supplier of hotelSuppliers) {
								const doesExist = db
									.collection(`bookings/${booking.id}/hotels`)
									.where("hotelID", "==", supplier)
									.get()
									.then((hotelDocs) => {
										return hotelDocs.docs?.length > 0
									})

								if (doesExist) {
									await db
										.doc(`hotels/${supplier}`)
										.get()
										.then((hotelDocData) => {
											const suppData = hotelDocData.data()
											console.log("Added " + suppData?.name)
											appData.push({
												...bookingInfo,
												Supplier: suppData?.name || "removed_hotel",
											})
										})
								}
							}
						}

						if (roundSuppliers && roundSuppliers?.length > 0) {
							for (let supplier of roundSuppliers) {
								const courseName = await db
									.doc(`courses/${supplier}`)
									.get()
									.then((roundDocData) => {
										const suppData = roundDocData.data()
										console.log("Added " + suppData?.name)
										return suppData?.name || "removed_course"
									})

								await db
									.collection(`bookings/${booking.id}/rounds`)
									.where("courseID", "==", supplier)
									.limit(1)
									.get()
									.then((roundDocData) => {
										if (roundDocData.docs?.length > 0) {
											const suppData = roundDocData.docs[0].data()
											console.log("Added price " + courseName + " (" + suppData.cost_per_golfer + ")")
											appData.push({
												...bookingInfo,
												Type: "course",
												Supplier: courseName,
												RoundsInvoice: isNaN(suppData.cost_per_golfer) ? 0 : suppData.cost_per_golfer,
											})
										}
									})
							}
						}

						if (transferSupplier && transferSupplier.length > 0) {
							await db
								.doc(`transfers/${transferSupplier}`)
								.get()
								.then((transferDocData) => {
									const suppData = transferDocData.data()
									console.log("Added " + suppData?.name)
									appData.push({
										...bookingInfo,
										Type: "transfer",
										Supplier: suppData?.name || "removed_transfer",
									})
								})
						}

						console.log("")
					}
				})
		}

		supplierDataToExcel(appData)
	}

	const fetchAllCourseData = async () => {
		const suppliers = await db.collection("courses").orderBy("name", "asc").limit(50).get()
		let allData = []
		let increm = 1

		// Iterate over each supplier
		for (let supplierDoc of suppliers.docs) {
			const supplierID = supplierDoc.id
			const supplierName = supplierDoc.data().name
			console.log("Aggregating " + supplierName)
			console.log(increm + "/" + suppliers.docs.length)
			increm++

			// Create an object to store supplier's aggregated data
			let supplierData = {
				supplier: supplierName,
				bookings_2022: 0,
				bookings_2023: 0,
				bookings_2024: 0,
				bookings_2025: 0,
				golfers_2022: 0,
				golfers_2023: 0,
				golfers_2024: 0,
				golfers_2025: 0,
			}

			// Define date ranges for each year
			const dateRanges = {
				2022: [new Date("2022-01-01"), new Date("2022-12-31")],
				2023: [new Date("2023-01-01"), new Date("2023-12-31")],
				2024: [new Date("2024-01-01"), new Date("2024-12-31")],
				2025: [new Date("2025-01-01"), new Date("2025-12-31")],
			}

			// Fetch and tally booking data for each year
			for (let year in dateRanges) {
				const [startTimestamp, endTimestamp] = dateRanges[year]

				const bookingDocs = await db
					.collection("bookings")
					.where("check_in", ">=", startTimestamp)
					.where("check_in", "<=", endTimestamp)
					.where("suppliers_rounds", "array-contains", supplierID)
					.where("removed", "==", false)
					.get()

				let bookingsArr = []
				let totalGolfers = 0

				bookingDocs.forEach((bookingDoc) => {
					const bookingData = bookingDoc.data()
					bookingsArr.push(bookingData)
					totalGolfers += bookingData.golfers || 0
				})

				supplierData[`bookings_${year}`] = bookingsArr.length
				supplierData[`golfers_${year}`] = totalGolfers
			}

			// Calculate percentage increases
			supplierData["bookings_2022_2023_increase"] = calculatePercentageIncrease(supplierData.bookings_2022, supplierData.bookings_2023) || 0
			supplierData["bookings_2023_2024_increase"] = calculatePercentageIncrease(supplierData.bookings_2023, supplierData.bookings_2024) || 0
			supplierData["bookings_2024_2025_increase"] = calculatePercentageIncrease(supplierData.bookings_2024, supplierData.bookings_2025) || 0

			supplierData["golfers_2022_2023_increase"] = calculatePercentageIncrease(supplierData.golfers_2022, supplierData.golfers_2023) || 0
			supplierData["golfers_2023_2024_increase"] = calculatePercentageIncrease(supplierData.golfers_2023, supplierData.golfers_2024) || 0
			supplierData["golfers_2024_2025_increase"] = calculatePercentageIncrease(supplierData.golfers_2024, supplierData.golfers_2025) || 0

			allData.push(supplierData)
		}

		return allData
	}

	const fetchAllTransfersData = async () => {
		const suppliers = await db.collection("transfers").orderBy("name", "asc").get()
		let allData = []
		let increm = 1

		// Iterate over each supplier
		for (let supplierDoc of suppliers.docs) {
			const supplierID = supplierDoc.id
			const supplierName = supplierDoc.data().name
			console.log("Aggregating " + supplierName)
			console.log(increm + "/" + suppliers.docs.length)
			increm++

			// Create an object to store supplier's aggregated data
			let supplierData = {
				supplier: supplierName,
				bookings_2022: 0,
				bookings_2023: 0,
				bookings_2024: 0,
				bookings_2025: 0,
				golfers_2022: 0,
				golfers_2023: 0,
				golfers_2024: 0,
				golfers_2025: 0,
			}

			// Define date ranges for each year
			const dateRanges = {
				2022: [new Date("2022-01-01"), new Date("2022-12-31")],
				2023: [new Date("2023-01-01"), new Date("2023-12-31")],
				2024: [new Date("2024-01-01"), new Date("2024-12-31")],
				2025: [new Date("2025-01-01"), new Date("2025-12-31")],
			}

			// Fetch and tally booking data for each year
			for (let year in dateRanges) {
				const [startTimestamp, endTimestamp] = dateRanges[year]

				const bookingDocs = await db
					.collection("bookings")
					.where("check_in", ">=", startTimestamp)
					.where("check_in", "<=", endTimestamp)
					.where("transfer", "==", supplierID)
					.where("removed", "==", false)
					.get()

				let bookingsArr = []
				let totalGolfers = 0

				bookingDocs.forEach((bookingDoc) => {
					const bookingData = bookingDoc.data()
					bookingsArr.push(bookingData)
					totalGolfers += bookingData.golfers || 0
				})

				supplierData[`bookings_${year}`] = bookingsArr.length
				supplierData[`golfers_${year}`] = totalGolfers
			}

			// Calculate percentage increases
			supplierData["bookings_2022_2023_increase"] = calculatePercentageIncrease(supplierData.bookings_2022, supplierData.bookings_2023) || 0
			supplierData["bookings_2023_2024_increase"] = calculatePercentageIncrease(supplierData.bookings_2023, supplierData.bookings_2024) || 0
			supplierData["bookings_2024_2025_increase"] = calculatePercentageIncrease(supplierData.bookings_2024, supplierData.bookings_2025) || 0

			supplierData["golfers_2022_2023_increase"] = calculatePercentageIncrease(supplierData.golfers_2022, supplierData.golfers_2023) || 0
			supplierData["golfers_2023_2024_increase"] = calculatePercentageIncrease(supplierData.golfers_2023, supplierData.golfers_2024) || 0
			supplierData["golfers_2024_2025_increase"] = calculatePercentageIncrease(supplierData.golfers_2024, supplierData.golfers_2025) || 0

			allData.push(supplierData)
		}

		return allData
	}

	const fetchSupplierStatsAndExport = async () => {
		const hotels = await fetchAllHotelData()
		console.log("Hotels suppliers:", hotels.length)
		const courses = await fetchAllCourseData()
		console.log("Course suppliers:", courses.length)
		const transfers = await fetchAllTransfersData()
		console.log("Transfer suppliers:", transfers.length)

		exportToExcel(hotels, courses, transfers)
	}

	const exportToExcel = (supplierData, coursesData, transfersData) => {
		// Format Supplier Data (Hotels)
		const formattedSupplierData = supplierData.map((item) => ({
			Supplier: item.supplier,

			// Bookings section
			"2022 Bookings": item.bookings_2022,
			"2023 Bookings": item.bookings_2023,
			"2024 Bookings": item.bookings_2024,
			"2025 Bookings": item.bookings_2025,
			" ": "",
			"% Increase 2022-2023 Bookings": item.bookings_2022_2023_increase ? `${item.bookings_2022_2023_increase.toFixed(2)}%` : "N/A",
			"% Increase 2023-2024 Bookings": item.bookings_2023_2024_increase ? `${item.bookings_2023_2024_increase.toFixed(2)}%` : "N/A",
			"% Increase 2024-2025 Bookings": item.bookings_2024_2025_increase ? `${item.bookings_2024_2025_increase.toFixed(2)}%` : "N/A",
			"  ": "",

			// Golfers section
			"2022 Golfers": item.golfers_2022,
			"2023 Golfers": item.golfers_2023,
			"2024 Golfers": item.golfers_2024,
			"2025 Golfers": item.golfers_2025,
			"   ": "",
			"% Increase 2022-2023 Golfers": item.golfers_2022_2023_increase ? `${item.golfers_2022_2023_increase.toFixed(2)}%` : "N/A",
			"% Increase 2023-2024 Golfers": item.golfers_2023_2024_increase ? `${item.golfers_2023_2024_increase.toFixed(2)}%` : "N/A",
			"% Increase 2024-2025 Golfers": item.golfers_2024_2025_increase ? `${item.golfers_2024_2025_increase.toFixed(2)}%` : "N/A",
		}))

		// Format Courses Data
		const formattedCoursesData = coursesData.map((item) => ({
			Course: item.supplier,

			// Bookings section
			"2022 Bookings": item.bookings_2022,
			"2023 Bookings": item.bookings_2023,
			"2024 Bookings": item.bookings_2024,
			"2025 Bookings": item.bookings_2025,
			" ": "",
			"% Increase 2022-2023 Bookings": item.bookings_2022_2023_increase ? `${item.bookings_2022_2023_increase.toFixed(2)}%` : "N/A",
			"% Increase 2023-2024 Bookings": item.bookings_2023_2024_increase ? `${item.bookings_2023_2024_increase.toFixed(2)}%` : "N/A",
			"% Increase 2024-2025 Bookings": item.bookings_2024_2025_increase ? `${item.bookings_2024_2025_increase.toFixed(2)}%` : "N/A",
			"  ": "",

			// Golfers section
			"2022 Golfers": item.golfers_2022,
			"2023 Golfers": item.golfers_2023,
			"2024 Golfers": item.golfers_2024,
			"2025 Golfers": item.golfers_2025,
			"   ": "",
			"% Increase 2022-2023 Golfers": item.golfers_2022_2023_increase ? `${item.golfers_2022_2023_increase.toFixed(2)}%` : "N/A",
			"% Increase 2023-2024 Golfers": item.golfers_2023_2024_increase ? `${item.golfers_2023_2024_increase.toFixed(2)}%` : "N/A",
			"% Increase 2024-2025 Golfers": item.golfers_2024_2025_increase ? `${item.golfers_2024_2025_increase.toFixed(2)}%` : "N/A",
		}))

		// Format Transfers Data
		const formattedTransfersData = transfersData.map((item) => ({
			Transfer: item.supplier,

			// Bookings section
			"2022 Bookings": item.bookings_2022,
			"2023 Bookings": item.bookings_2023,
			"2024 Bookings": item.bookings_2024,
			"2025 Bookings": item.bookings_2025,
			" ": "",
			"% Increase 2022-2023 Bookings": item.bookings_2022_2023_increase ? `${item.bookings_2022_2023_increase.toFixed(2)}%` : "N/A",
			"% Increase 2023-2024 Bookings": item.bookings_2023_2024_increase ? `${item.bookings_2023_2024_increase.toFixed(2)}%` : "N/A",
			"% Increase 2024-2025 Bookings": item.bookings_2024_2025_increase ? `${item.bookings_2024_2025_increase.toFixed(2)}%` : "N/A",
			"  ": "",

			// Golfers section
			"2022 Golfers": item.golfers_2022,
			"2023 Golfers": item.golfers_2023,
			"2024 Golfers": item.golfers_2024,
			"2025 Golfers": item.golfers_2025,
			"   ": "",
			"% Increase 2022-2023 Golfers": item.golfers_2022_2023_increase ? `${item.golfers_2022_2023_increase.toFixed(2)}%` : "N/A",
			"% Increase 2023-2024 Golfers": item.golfers_2023_2024_increase ? `${item.golfers_2023_2024_increase.toFixed(2)}%` : "N/A",
			"% Increase 2024-2025 Golfers": item.golfers_2024_2025_increase ? `${item.golfers_2024_2025_increase.toFixed(2)}%` : "N/A",
		}))

		// Create Workbook and Add Sheets
		const workbook = XLSX.utils.book_new()

		// Add Hotels Sheet
		const worksheetHotels = XLSX.utils.json_to_sheet(formattedSupplierData)
		XLSX.utils.book_append_sheet(workbook, worksheetHotels, "Hotels")

		// Add Courses Sheet
		const worksheetCourses = XLSX.utils.json_to_sheet(formattedCoursesData)
		XLSX.utils.book_append_sheet(workbook, worksheetCourses, "Courses")

		// Add Transfers Sheet
		const worksheetTransfers = XLSX.utils.json_to_sheet(formattedTransfersData)
		XLSX.utils.book_append_sheet(workbook, worksheetTransfers, "Transfers")

		// Save to a file
		XLSX.writeFile(workbook, "supplier_travellers_22-25.xlsx")
	}

	// Update all the collections and subcollections
	const updateBS3Collections = async () => {
		// Collections and all available subcollections
		const collections = {
			CMS_courses: ["discounts", "price_windows"],
			CMS_holidays: ["discounts", "price_windows"],
		}

		// Limit the number of documents fetched
		const LIMIT = 100

		// Iterate over each collection
		for (const collectionName of Object.keys(collections)) {
			console.log(`Processing collection: ${collectionName}`)

			// Step 1: Get limited main collection documents
			const collectionRef = db.collection(collectionName)
			const mainDocsSnapshot = await collectionRef.limit(LIMIT).get()
			const totalMainDocs = mainDocsSnapshot.size
			console.log(`Fetched ${totalMainDocs} documents from collection ${collectionName}`)

			let processedDocs = 0

			for (const doc of mainDocsSnapshot.docs) {
				processedDocs++

				// Update the main document
				await doc.ref.set(
					{
						bs3_update: firebase.firestore.FieldValue.serverTimestamp(),
					},
					{ merge: true }
				)

				console.log(`Updated document ${processedDocs}/${totalMainDocs} in collection ${collectionName}`)

				// Step 2: Update subcollection documents
				const subcollections = collections[collectionName]
				for (const subcollection of subcollections) {
					const subcollectionRef = doc.ref.collection(subcollection)
					const subDocsSnapshot = await subcollectionRef.limit(LIMIT).get()
					const totalSubDocs = subDocsSnapshot.size
					let processedSubDocs = 0

					console.log(`  Fetched ${totalSubDocs} documents from subcollection ${subcollection} for document ${doc.id}`)

					for (const subDoc of subDocsSnapshot.docs) {
						processedSubDocs++

						await subDoc.ref.update({
							bs3_update: firebase.firestore.FieldValue.serverTimestamp(),
						})

						console.log(`  Updated subcollection document ${processedSubDocs}/${totalSubDocs} in subcollection ${subcollection}`)
					}

					console.log(`  Subcollection ${subcollection} update complete for document ${doc.id}`)
				}

				console.log(`${totalMainDocs - processedDocs} documents left in main collection ${collectionName}`)
				console.log("")
			}

			console.log(`Collection ${collectionName} update complete.`)
			console.log("")
			console.log("----------------------------------------------------------------------------------------------------")
			console.log("")
		}
		console.log("Update complete")
	}

	const fetchBookingAverages = async () => {
		try {
			const startOf2024 = new Date("2024-01-01T00:00:00Z")
			const startOf2025 = new Date("2025-01-01T00:00:00Z")

			const bookingSnapshot = await db.collection("bookings").where("created", ">=", startOf2024).where("created", "<", startOf2025).orderBy("created").get()

			if (bookingSnapshot.empty) {
				console.log("No bookings found for 2024.")
				return
			}

			const groupSizeStats = {}

			bookingSnapshot.forEach((bookingDoc) => {
				const { golfers = 0, non_golfers = 0, margins = {} } = bookingDoc.data()
				const groupSize = golfers + non_golfers
				const margin = margins.profit || 0

				if (!groupSizeStats[groupSize]) {
					groupSizeStats[groupSize] = []
				}

				groupSizeStats[groupSize].push(parseFloat(margin.toFixed(2)))
			})

			Object.keys(groupSizeStats).forEach((size) => {
				const marginsArray = groupSizeStats[size]

				marginsArray.sort((a, b) => a - b)

				const count = marginsArray.length
				const totalMargin = marginsArray.reduce((acc, m) => acc + m, 0)
				const avgMargin = (totalMargin / count).toFixed(2)

				const percentileValue = (p) => {
					const index = Math.floor(p * count)
					return marginsArray[index]
				}

				const lowestMargin = marginsArray[0]
				const highestMargin = marginsArray[marginsArray.length - 1]
				const median = percentileValue(0.5)
				const p90 = percentileValue(0.9)
				const p95 = percentileValue(0.95)

				console.log(`${size},${count},${avgMargin},${lowestMargin},${highestMargin},${median},${p90},${p95}`)
			})
		} catch (error) {
			console.error("Error fetching and calculating booking averages:", error)
		}
	}

	const fetchOnlineBookings = async () => {
		try {
			const bookingSnapshot = await db.collection("direct_bookings").where("client", "!=", null).get()

			console.log("Total online bookings:", bookingSnapshot.size)

			if (bookingSnapshot.empty) {
				console.log("No bookings found.")
				return
			}

			const monthlyData = {}

			bookingSnapshot.forEach((doc) => {
				const data = doc.data()
				const { created, paid_status } = data

				if (!created) return

				let date
				if (created.toDate) {
					date = created.toDate()
				} else {
					date = new Date(created)
				}

				const year = date.getUTCFullYear()
				const month = (date.getUTCMonth() + 1).toString().padStart(2, "0")
				const ymKey = `${year}-${month}`

				if (!monthlyData[ymKey]) {
					monthlyData[ymKey] = { total: 0, paid: 0, partPaid: 0 }
				}

				monthlyData[ymKey].total++

				if (paid_status === "PAID") {
					monthlyData[ymKey].paid++
				} else if (paid_status === "PART_PAID") {
					monthlyData[ymKey].partPaid++
				}
			})

			const sortedKeys = Object.keys(monthlyData).sort((a, b) => {
				const [yearA, monthA] = a.split("-").map(Number)
				const [yearB, monthB] = b.split("-").map(Number)
				if (yearA === yearB) {
					return monthA - monthB
				}
				return yearA - yearB
			})

			console.log("Year,Month,Total Bookings,MoM % Change (Total),Paid Count,Part-Paid Count,% Paid+Part_Paid,MoM % Change (% Paid+Part_Paid)")

			let prevTotal = null
			let prevPaidRatio = null

			for (const key of sortedKeys) {
				const [yr, mo] = key.split("-")
				const { total, paid, partPaid } = monthlyData[key]

				const paidRatio = total > 0 ? ((paid + partPaid) / total) * 100 : 0

				let momChangeTotal = "N/A"
				if (prevTotal !== null && prevTotal > 0) {
					const change = ((total - prevTotal) / prevTotal) * 100
					momChangeTotal = change.toFixed(2) + "%"
				}

				let momChangePaidRatio = "N/A"
				if (prevPaidRatio !== null && prevPaidRatio !== 0) {
					const ratioChange = ((paidRatio - prevPaidRatio) / prevPaidRatio) * 100
					momChangePaidRatio = ratioChange.toFixed(2) + "%"
				}

				console.log(`${yr},${mo},${total},${momChangeTotal},${paid},${partPaid},${paidRatio.toFixed(2)}%,${momChangePaidRatio}`)

				prevTotal = total
				prevPaidRatio = paidRatio
			}
		} catch (error) {
			console.error("Error fetching online bookings:", error)
		}
	}

	return (
		<Tile fullPage={true}>
			<Title className="flex">
				<h1>System data</h1>
			</Title>

			<Button
				label="Fetch booking averages"
				onClick={() => fetchOnlineBookings()}
			/>

			{/* <div style={{ display: "grid", gridTemplateColumns: "300px 145px", gridGap: "10px" }}>
                <Input
                    type="text"
                    value={supplierID}
                    onChange={setSupplierID} />

                <Button
                    label="Query supplier"
                    onClick={() => generateIndex()} />
            </div>

            {supplierDuplictes > 0 && (
                <small style={{ fontSize: "12px", marginTop: "6px", display: "block" }}>Estimated duplicates: {supplierDuplictes}</small>
            )}

            <table style={{ marginTop: "14px" }}>
                <thead>
                    <tr style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                        <td style={{ fontSize: "14px", opacity: "0.65", padding: "7.5px 10px", background: "#f7f7f7" }} colSpan={2}>{supplierTitle}</td>
                    </tr>
                </thead>
                <tbody>
                    {supplierCheck?.map((checkLine) => (
                        <tr style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                            <td style={{ padding: "7.5px 10px", fontSize: "14px" }}>{checkLine[0]}</td>
                            <td style={{ padding: "7.5px 20px 7.5px 10px", fontSize: "14px" }}>{checkLine[1]}</td>
                        </tr>
                    ))}

                    {canDelete && (
                        <tr>
                            <td style={{ padding: "7.5px 10px", fontSize: "14px" }}></td>
                            <td style={{ padding: "7.5px 20px 7.5px 10px", fontSize: "14px" }}>
                                <Button
                                    label="Delete"
                                    onClick={() => deleteSupplier()} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table> */}
		</Tile>
	)
}
