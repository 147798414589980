import React, { useEffect, useState } from "react"
import { db, auth, arrayUnion } from "../../../utils/firebase"
import randomstring from "randomstring"
import firebase from "firebase"
import moment from "moment"
import "./assign.scss"

// UI components & structure
import Window from "../../../components/structure/window/window"
import Button from "../../../components/ui/button/button"

// Shows a window for assigning the booking to a sales agent
export default function Assign({ bookingID, bookingDetails, clientID, close }) {
	const [agents, setAgents] = useState([])
	const [assiging, setAssigning] = useState({})

	// On component load
	useEffect(() => {
		// Pull the agents that are available to be assigned bookings
		db.collection("users")
			.orderBy("first_name")
			.where("show_for.assignable", "==", true)
			.get()
			.then((agentDocs) => {
				// Store the agent records in an array
				let agents = []

				// Loop through the agent documents found
				agentDocs.forEach((agentDoc) => {
					// Push the agent into the array
					agents.push({
						...agentDoc.data(),
						id: agentDoc.id,
					})
				})

				// Push them into the state
				setAgents(agents)
			})
	}, [])

	// Assign the booking to the agent
	const assignBookingToAgent = async (agentID) => {
		// Show a spinner on the relevant agent button
		setAssigning({ [agentID]: true })

		// Assign the booking to the agent
		await db.doc(`direct_bookings/${bookingID}`).set(
			{
				agent: agentID,
				status: "BEING_PROCESSED",
			},
			{ merge: true }
		)

		// Get the date from timestamp as a millisecond value
		const dateFromMillis = moment(bookingDetails?.date_from?.seconds, "X").valueOf()

		// Fetch the location data from the database
		const location = await db
			.doc(`CMS_holidays/${bookingDetails.resort}`)
			.get()
			.then((resortDoc) => {
				// Return the resort data
				return resortDoc.data().title
			})

		// Make a new booking record with the enquiry details
		const newBookingID = await db
			.collection("bookings")
			.add({
				agent: agentID,
				client: clientID,
				reference: bookingDetails.reference,
				booked: firebase.firestore.FieldValue.serverTimestamp(),
				created: firebase.firestore.FieldValue.serverTimestamp(),
				date_from: firebase.firestore.Timestamp.fromMillis(dateFromMillis),
				enquiry: {
					site: "GHD",
					location: location,
				},
				direct_booking_details: bookingDetails,
				direct_booking_id: bookingID,
				booking_type: "DIRECT",
				paid_by_client: 0,
				total_cost: bookingDetails?.total_cost,
				removed: false,
			})
			.then((bookingDoc) => {
				return bookingDoc.id
			})

		// Add the voucher code amount in as a payment
		if (bookingDetails.discount_code && bookingDetails.discount_code === "GHDBF20") {
			await db.collection(`bookings/${newBookingID}/payments`).add({
				amount: bookingDetails.group_size * 20,
				payee: "SYSTEM",
				reference: "GHDBF20 x " + bookingDetails.group_size + " golfers",
				paid_date: firebase.firestore.FieldValue.serverTimestamp(),
				created: firebase.firestore.FieldValue.serverTimestamp(),
				saved_by: null,
			})
		}

		// Add a log to the booking document
		await db.collection(`bookings/${newBookingID}/logs`).add({
			type: "BOOKING_CREATED",
			message: "Booking was created from a direct booking through the website",
			user: auth.currentUser.uid,
			created: firebase.firestore.FieldValue.serverTimestamp(),
		})

		// Add a log for the math trail used in the price generation
		await db.collection(`bookings/${newBookingID}/logs`).add({
			badge: "SPECIAL",
			type: "MATH_TRAIL",
			message: bookingDetails?.price_math_trail,
			created: firebase.firestore.FieldValue.serverTimestamp(),
		})

		// Get the client details from the database
		const clientDetails = await db
			.doc(`clients/${clientID}`)
			.get()
			.then((clientDoc) => {
				// Return the client data
				return clientDoc.data()
			})

		// Loop over the current payment records and write them into the new booking subcollection
		await db
			.collection(`direct_bookings/${bookingID}/payments`)
			.get()
			.then((paymentDocs) => {
				paymentDocs.forEach((paymentDoc) => {
					db.doc(`bookings/${newBookingID}/payments/${paymentDoc.id}`).set({
						...paymentDoc.data(),
						created: firebase.firestore.FieldValue.serverTimestamp(),
						paid_date: firebase.firestore.FieldValue.serverTimestamp(),
						reference: "Website Booking",
						payee: `${clientDetails.first_name} ${clientDetails.last_name}`,
						saved_by: null,
					})
				})
			})

		// Add the bookingID to the clients booking array and remove the enquiry
		await db.doc(`clients/${clientID}`).set(
			{
				bookings: arrayUnion(newBookingID),
			},
			{ merge: true }
		)

		// Add a log to the client document
		await db.collection(`clients/${clientID}/logs`).add({
			type: "BOOKING_CREATED",
			badge: "SUCCESS",
			message: `Direct booking was confirmed, new booking reference is ${bookingDetails.reference}`,
			user: auth.currentUser.uid,
			created: firebase.firestore.FieldValue.serverTimestamp(),
		})

		// Reset the state
		setAssigning({ [agentID]: false })
		close()
	}

	return (
		<Window
			title="Assign to agent"
			className="slim"
			close={() => close()}>
			<div className="assign-agent-wrap">
				<table className="assign-agent-table">
					<tbody>
						{/* Loop through the agents and print them into the DOM */}
						{agents.map((agent) => (
							<tr key={agent.id}>
								<td>
									{agent.first_name} {agent.last_name}
								</td>
								<td>
									<Button
										label="Assign"
										loading={assiging[agent.id]}
										small={true}
										loadingText="Assigning..."
										onClick={() => assignBookingToAgent(agent.id)}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Window>
	)
}
