import React, { useState, useEffect } from "react"
import { db } from "../../utils/firebase"
import "./quotes.scss"

// UI components
import Tile from "../../components/structure/tile/tile"
import Table from "../../components/structure/table/table"
import Title from "../../components/structure/title/title"
import QuoteRow from "./_components/quote"

// Functional component to return the quotes being generated
export default function AllQuotes() {
	const [loading, setLoading] = useState(true)
	const [quotes, setQuotes] = useState([])

	// On component load
	useEffect(() => {
		// Fetch the latest 200 quotes
		db.collection("quotes")
			.orderBy("created", "desc")
			.limit(200)
			.get()
			.then((querySnapshot) => {
				const quotes = []
				querySnapshot.forEach((doc) => {
					quotes.push({ id: doc.id, ...doc.data() })
				})
				setQuotes(quotes)
				setLoading(false)
			})
	}, [])

	return (
		<Tile fullPage={true}>
			<Title className="flex">
				<h1>All Quotes</h1>
			</Title>

			<Table
				className="enquiries-table"
				headings={["Client Name", "Agent", "Created"]}>
				{loading && (
					<tr>
						<td colSpan="8">Loading...</td>
					</tr>
				)}

				{!loading &&
					quotes.map((quote, index) => (
						<QuoteRow
							key={index}
							quote={quote}
						/>
					))}
			</Table>
		</Tile>
	)
}
